<template>
  <div class="FQA-wrap">
    <div class="banner">
      <div class="search-wrap">
        <mt-field placeholder="请输入您想搜索的内容" v-model="searchKey">
          <img @click="searchFqaList" class="search-icon" src="https://fs.zhbim.com/imgs/mobile/customer/search-icon.png"
            height="45px" width="100px">
        </mt-field>
      </div>
    </div>
    <div class="main">
      <h2>可能遇到的常见问题</h2>
      <p class="des">为您整理出可能遇到的常见问题并解答，您可根据解答<br />快速解决问题</p>
      <div class="module-title">
        <div class="module-title-wrap">
          <span>{{moduleName}}</span>
          <img @click="showTitleList=!showTitleList" class="right" src="https://fs.zhbim.com/imgs/mobile/home/catalog.png" alt="">
        </div>
        <ul v-show="showTitleList" class="title-list">
          <template v-for="(item, index) in product">
            <li @click="getModule(item.code)" :class="activeName===item.code?'active':''" v-if="item.code!='ZH.Plugin.YQBY'"
              :key="index">
              {{item.code != 'ZH.Plugin.YQBY' ? item.name + '模块' : item.name}}</li>
          </template>
        </ul>
      </div>
      <van-collapse v-model="activeKey" accordion>
        <template v-for="item,index in fqaList">
          <van-collapse-item v-if="index<showNum" :key="item.key" :title="item.question" :name="item.key">
            <p class="question">{{item.question}}</p>
            <p class="answer">{{item.answer}}</p>
          </van-collapse-item>
        </template>
      </van-collapse>
      <div>
        <div v-if="fqaList.length>showNum" @click="showNum+=6" class="look-more">展开查看更多<img
            src="https://fs.zhbim.com/imgs/mobile/product/down.png">
        </div>
        <div v-if="(fqaList.length<showNum||fqaList.length==showNum)&&fqaList.length!=0" class="no-more">暂无更多常见问题</div>
      </div>
      <div class="noData" v-if="fqaList.length==0">
        <div class="imgBox">
          <img src="@/assets/img/noData.png" alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手" title="暂无数据" />
        </div>
        <p class="p1">暂无常见问题</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getFqaList } from '@/api/FQA'
import { productSearch } from '@/api/product'
export default {
  data() {
    return {
      showNum: 6,
      searchKey: '',
      showTitleList: false,
      product: [],
      activeName: 'ZH.Plugin.General',
      activeKey: '',
      fqaList: []
    }
  },
  created() {
    this.getProduct()
    this.searchFqaList()
  },
  computed: {
    moduleName() {
      for (let i = 0; i < this.product.length; i++) {
        const element = this.product[i];
        console.log(element);
        if (this.activeName === element.code) {
          let name = ''
          return name = this.activeName != 'ZH.Plugin.YQBY' ? element.name + '模块' : element.name
        }
      }
    }
  },
  watch: {
    activeName() {
      this.searchFqaList()
    },
    searchKey(newKey, oldKey) {
      if (!newKey) {
        this.searchFqaList()
      }
    }
  },
  methods: {
    getProduct() {
      productSearch().then((res) => {
        this.product = res.items
      })
    },
    async searchFqaList() {
      let res = await getFqaList({ productCode: this.activeName, searchKey: this.searchKey })
      this.fqaList = res
      console.log(this.fqaList);
    },
    getModule(code) {
      this.showTitleList = false,
        this.activeName = code
    },
  }

}
</script>
<style lang="scss" scoped>
.FQA-wrap {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/customer/FAQ-banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5rem;
    text-align: center;
    padding: 0 0.3rem;
    .search-wrap {
      padding-top: 3.4rem;
      .mint-field {
        height: 0.8rem;
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-shadow: 0px 5px 5px 0px rgba(232, 234, 244, 0.49);
        border-radius: 0.4rem;
        ::v-deep .mint-cell-wrapper {
          padding: 0 0.34rem;
          font-size: 0.24rem;
        }
        ::v-deep .mint-field-clear {
          margin-right: 0.2rem;
        }
        .search-icon {
          width: 0.36rem;
          height: 0.36rem;
        }
      }
    }
  }
  .main {
    h2 {
      padding-top: 0.52rem;
      text-align: center;
      font-size: 0.42rem;
      line-height: 0.8rem;
      font-weight: 500;
      color: #333333;
    }
    .des {
      font-size: 0.24rem;
      font-weight: 500;
      color: #666666;
      line-height: 0.32rem;
      text-align: center;
      padding-bottom: 0.68rem;
    }
    .module-title {
      position: relative;
      .module-title-wrap {
        padding: 0.45rem 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #ffffff;
        background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
        box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
        span {
          font-size: 0.32rem;
          line-height: 0.32rem;
          color: #4a86ff;
        }
        img {
          width: 0.34rem;
          height: 0.28rem;
        }
      }
      ul {
        position: absolute;
        top: 1rem;
        left: 0;
        width: 100%;
        background: #f7f7fb;
        box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
        opacity: 0.9;
        border-radius: 2px;
        z-index: 99;
        padding: 0 0.33rem;
        li {
          padding: 0.25rem 0;
          font-size: 0.3rem;
          color: #666666;
        }
        .active {
          color: #4a86ff;
        }
      }
    }
    ::v-deep .van-collapse-item__wrapper {
      background-color: #f4f5f9;
      box-shadow: 0px 8px 10px 0px rgba(213, 219, 243, 0.6);
      padding: 0.42rem 0.3rem;
      .van-collapse-item__content {
        background-color: #f4f5f9;
        padding: 0;
        .question {
          font-size: 0.28rem;
          color: #333333;
          line-height: 0.42rem;
          margin-bottom: 0.4rem;
        }
        .answer {
          font-size: 0.24rem;
          color: #666666;
          line-height: 0.42rem;
        }
      }
    }
    ::v-deep .van-cell {
      height: 1.2rem;
      background: #fff;
      box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
      line-height: 1.2rem;
      padding: 0 0.3rem;
      font-size: 0.28rem;
      color: #333;
      &::after {
        display: none !important;
      }
      .van-cell__title {
        span {
          display: inline-block;
          width: 6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .van-cell__left-icon,
      .van-cell__right-icon {
        line-height: 1.2rem;
      }
    }
    ::v-deep .van-collapse-item__title--expanded {
      color: #4a86ff;
      .van-cell__left-icon,
      .van-cell__right-icon {
        color: #4a86ff;
      }
    }
    .look-more {
      text-align: center;
      font-size: 0.28rem;
      font-weight: 500;
      color: #4a86ff;
      padding: 0.42rem 0;
      img {
        vertical-align: middle;
        width: 0.22rem;
        height: auto;
        margin-left: 0.1rem;
      }
    }
    .no-more {
      padding: 0.42rem 0;
      text-align: center;
      font-size: 0.28rem;
      font-weight: 500;
      color: #999;
    }
    .noData {
      text-align: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      .imgBox {
        margin-bottom: 0.3rem;
        img {
          width: 100%;
          height: auto;
        }
      }
      .p1 {
        color: #333;
        font-size: 0.3rem;
        font-weight: 500;
      }
    }
  }
}
</style>