<template>
  <div class="banner">
    <div class="swipe">
      <mt-swipe :auto="4000">
        <mt-swipe-item
          v-for="(item, index) in bannerList"
          :key="index"
          @click.native="linkTo('banner', item)"
        >
          <div
            class="item"
            :style="`background:url(${item.img}) top right no-repeat;background-size:100% 100%;`"
          >
            <p class="title" :style="{ color: item.titleColor }">
              {{ item.title }}
            </p>
            <p class="text" :style="{ color: item.descriptionColor }">
              {{ item.description }}
            </p>
          </div>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in auxiliaryList"
        :key="index"
        @click="linkTo('block', item.name)"
      >
        <img :src="item.icon" alt="" title="" class="img" />
        <p class="title">{{ item.title }}</p>
      </div>
    </div>
    <pcmask text="请前往pc端下载中心下载使用<br/>（网址：https://www.zhbim.com/）" ref="pcmask"></pcmask>
    <pcmask :state="2" ref="pcmask1"></pcmask>
  </div>
</template>

<script>
import { bannerList } from "@/api/home";
import pcmask from "@/components/pcmask.vue";
export default {
  name: "banner",
  data() {
    return {
      bannerList: [],
      auxiliaryList: [
        {
          name: "solutionMep",
          icon: "https://fs.zhbim.com/imgs/mobile/home/electromechanical_icon.png",
          title: "机电模块",
        },
        {
          name: "solutionAec",
          icon: "https://fs.zhbim.com/imgs/mobile/home/civil_engineering_icon.png",
          title: "土建模块",
        },
        {
          name: "solution",
          icon: "https://fs.zhbim.com/imgs/mobile/home/scheme_icon.png",
          title: "行业方案",
        },
      ],
    };
  },
  components: {
    pcmask,
  },
  mounted() {
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      bannerList({
        Terminal: 1,
      }).then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i].img) {
            this.bannerList.push(res[i]);
          }
        }
      });
    },
    linkTo(type, item) {
      if (type == "banner") {
        if (item.linkType == 0) {
          if (
            item.activityCode == "Christmas" ||
            item.activityCode == "NewYearWallpaper"
          ) {
            this.$refs.pcmask1.clickMask();
          } else {
            this.$refs.pcmask.clickMask();
          }
        } else if (item.linkType == 1) {
          this.$router.push({
            path: item.link,
          });
        } else {
          window.open(item.link);
        }
      } else {
        this.$router.push({
          name: item,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";
.banner {
  position: relative;
  padding-bottom: 1rem;
  .swipe {
    width: 100%;
    height: 6.3rem;
    .item {
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 0.4rem;
      .title {
        font-size: 0.48rem;
        color: #333;
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
      .text {
        font-size: 0.24rem;
        color: #666666;
        font-weight: 500;
      }
    }
  }
  .list {
    width: 100%;
    @include flex();
    padding: 0px 0.3rem;
    position: absolute;
    bottom: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .item {
      width: 32%;
      height: 1.2rem;
      line-height: 1.2rem;
      border: 0.02rem solid #fff;
      background: linear-gradient(180deg, #eff1f6 0%, #ffffff 100%);
      box-shadow: 0.05rem 0.03rem 0.06rem 0px rgba(232, 235, 247, 0.86);
      border-radius: 0.04rem;
      padding: 0px 0.2rem;
      position: relative;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      .img {
        width: 0.5rem;
        height: 0.4rem;
        margin-right: 0.06rem;
        position: absolute;
        left: 0.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .title {
        position: absolute;
        right: 0.2rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.28rem;
        color: #333;
        font-weight: 500;
      }
    }
  }
}
</style>
