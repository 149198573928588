<template>
  <div class="case-wrap">
    <div class="banner">
      <h2>建模助手精选案例</h2>
      <p>在建模助手协助下高效完成的各类复杂工程项目</p>
    </div>
    <ul class="case-list">
      <li class="case-item-wrap" :class="index%2===0?'left':'right'" @click="toDetail(item.id)" v-for="(item, index) in daList" :key="index">
        <div class="case-item">
          <div class="img-wrap"><img :src="item.img" alt=""></div>
          <h5>{{item.title}}</h5>
        </div>
      </li>
    </ul>
    <div v-if="totalCount!=daList.length" @click="totalCount>daList.length?PageIndex++:''" class="look-more">展开查看更多<img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png">
    </div>
    <div v-else class="no-more">暂无更多内容</div>
  </div>
</template>
<script>
import { getCaseList } from '@/api/resource'
export default {
  data() {
    return {
      daList: [],
      PageIndex: 1,
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  watch: {

    PageIndex(newVal, oldVal) {
      this.getData()
    }
  },
  methods: {
    getData() {
      let data = {
        PageIndex: this.PageIndex,
        PageSize: 4,
      }
      getCaseList(data).then((res) => {
        this.daList = this.daList.concat(res.items)
        this.totalCount = res.totalCount
        console.log(this.daList);
      })
    },
    toDetail(id) {
      this.$router.push({
        path: `/caseDetail/${id}`,
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.case-wrap {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/caseBnner.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 6.3rem;
    text-align: center;
    h2 {
      font-size: 0.42rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.4rem;
      padding-top: 0.4rem;
      padding-bottom: 0.36rem;
    }
    p {
      margin: 0 auto;
      font-size: 0.24rem;
      color: #666666;
      line-height: 0.36rem;
    }
  }
  .case-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.44rem 0;
    .case-item-wrap{
      width: 50%;
      padding: 0 0.3rem;
      box-sizing: border-box;

    }
    .left{
      padding-right: 0.15rem;
    }
    .right{
      padding-left: 0.15rem;
    }
    .case-item {
      background: linear-gradient(180deg, #eff1f6 0%, #ffffff 100%);
      box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
      border-radius: 4px;
      font-size: 0.24rem;
      color: #333333;
      margin-bottom: 0.3rem;
      h5 {
        line-height: 0.74rem;
        padding: 0 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
      .img-wrap {
        width: 100%;
        height: 1.86rem;
        overflow: hidden;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      img {
        width: 100%;
        height: auto;
        height: 1.86rem;
      }
    }
  }
  .look-more {
    text-align: center;
    font-size: 0.28rem;
    font-weight: 500;
    color: #4a86ff;
    padding-bottom: 0.55rem;
    img {
      vertical-align: middle;
      width: 0.22rem;
      height: auto;
      margin-left: 0.1rem;
    }
  }
  .no-more {
    text-align: center;
    font-size: 0.28rem;
    font-weight: 500;
    color: #999;
    padding-bottom: 0.55rem;
  }
}
</style>