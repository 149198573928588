//关于活动模块接口

import request from '@/utils/request'
// import request from 'request'

// 活动报名
export function activityAdd(data) {
  return request({
    url: 'activity/signup',
    method: 'POST',
    data
  })
}

// 加入战队
export function groupJoin(data) {
  return request({
    url: 'activity/group/join',
    method: 'POST',
    data
  })
}

// 获取用户所在的战队
export function getMyGroup(params) {
  return request({
    url: 'activity/group/my',
    method: 'GET',
    params
  })
}
// 搜索活动  api/activity/search
export function getActivityList(params) {
  return request({
    url: 'activity/search',
    method: 'GET',
    params
  })
}
// 领取我的奖励   /activity/my/reward
export function getActivityReward(params) {
  return request({
    url: 'activity/my/reward',
    method: 'GET',
    params
  })
}