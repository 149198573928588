//接口封装的方法
import axios from 'axios'
import {
  getToken,
  getRefshToken
} from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 180000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization': 'Bearer ' + getToken(),
    'X-Authorization': 'Bearer ' + getRefshToken(),
  }
});

// request拦截器
service.interceptors.request.use(config => {
  if (config.headers) {
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers['X-Authorization'] = 'Bearer ' + getRefshToken()
  }

  if (!config.params) {
    config.params = {}
  }
  return config
}, error => {
  console.log('request_error:' + error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const {
      status,
      statusText,
      data
    } = response
    if (status != 200) {
      // Message({
      //   message: statusText,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject('error')
    }

    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = data
    if (res.errorCode != 0) {
      if (response.config.url == process.env.VUE_APP_WX_LOGIN_URL) { //给微信登录的接口做特殊处理
        // Message({
        //   message: response.data.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        return Promise.reject(res)
      } else if (res.errorCode === 401) {


      } else {
        // Message({
        //   message: res.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        return Promise.reject(res)
      }
    }
    return res.data;
  },
  error => {
    if (error && error.response) {
      console.log('response_error', error.response) // for debug
      // Message({
      //   message: error.response.data.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(error.response)
    } else {
      console.log('response_error', error) // for debug
      // Message({
      //   message: error.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(error)
    }
  }
)

export default service
