<template>
  <baidu-map class="map" :center="map.center" :zoom="map.zoom" @ready="handler">
    <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
    <bm-geolocation
      anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
      :showAddressBar="false"
      :autoLocation="false"
    ></bm-geolocation>
    <bm-marker :position="map.center" :dragging="map.dragging"> </bm-marker>
  </baidu-map>
</template>
 
<script>
export default {
  name: "demo",
  data: () => ({
    map: {
      center: { lng: 113.332753, lat: 23.104476 },
      zoom: 20,
      show: false,
      dragging: false,
    },
  }),
  methods: {
    handler({ BMap, map }) {
      this.map = new BMap.Map("allmap", { enableMapClick: false });
      map.centerAndZoom(new BMap.Point(113.332753, 23.104476));
      let point = new BMap.Point(113.332753, 23.104476);
      map.centerAndZoom(point, 20);
      map.enableScrollWheelZoom(true);

      var point1 = new BMap.Point(113.332753, 23.104476);
      var content = "地址：广东省广州市海珠区赤岗街道TiT创意园创意东路4号";
      var label = new BMap.Label(content, {
        // 创建文本标注
        position: point1,
        offset: new BMap.Size(10, -50),
      });
      map.addOverlay(label); // 将标注添加到地图中
      label.setStyle({
        // 设置label的样式
        color: "#666",
        fontSize: "14px",
        border: "1px solid #1E90FF",
      });
    },
  },
};
</script>
 
<style scoped>
.map {
  width: 100%;
  height: 3rem;
}
/* .bm-marker{
        width: 20px;
        height: 20px;
    } */
.BMap_cpyCtrl {
  display: none;
  width: 0;
  height: 0;
}
.anchorBL {
  display: none;
}
img {
  width: 0;
  height: 0;
}
</style>
<style lang="scss">
.map {
  .anchorBR {
    display: none;
  }
}
</style>

