const packageJson = require('../package.json');

module.exports = {
  //标题
  title: packageJson.title,

  //版本号
  version: packageJson.version,

  //localStorage
  storage: 'localStorage',

  /**
   * @type {String} 'zh_'
   * @description 前缀,用于Storage数据缓存.
   */
  prefix: 'zh_',

  /**
   * @type {Number} 604,800
   * @description 缓存时间(秒数).
   * 默认 7 天
   */
  ttl: 604800
};
