<template>
  <div class="informationCenter">
    <div class="banner">
      <div class="title">资讯中心</div>
      <div class="text">分享技术干货，打造BIM行业影响力</div>
      <div class="search-wrap">
        <mt-field
          placeholder="请输入您想搜索的内容"
          @blur.native.capture="onBlur(keyword)"
          v-model="searchKey"
        >
          <img
            @click="searchCondition.searchKey = searchKey"
            class="search-icon"
            src="https://fs.zhbim.com/imgs/mobile/customer/search-icon.png"
            height="45px"
            width="100px"
          />
        </mt-field>
      </div>
    </div>
    <div class="tabList">
      <div
        class="tabItem"
        :class="searchCondition.column == item.code ? 'active' : ''"
        v-for="(item, index) in articleTypeList"
        :key="index"
        @click="(searchCondition.column = item.code), (activeText = item.text)"
      >
        {{ item.text }}
      </div>
    </div>
    <div class="tagBox">
      <p class="left">{{ activeText }}</p>
      <p class="right">
        <span
          :class="searchCondition.sortField == 'hot' ? 'cur' : ''"
          @click="searchCondition.sortField = 'hot'"
          >热度</span
        >&nbsp; | &nbsp;
        <span
          :class="searchCondition.sortField == 'publishtime' ? 'cur' : ''"
          @click="searchCondition.sortField = 'publishtime'"
          >时间</span
        >
      </p>
    </div>
    <mt-tab-container v-model="searchCondition.column">
      <mt-tab-container-item
        v-for="(item, index) in articleTypeList"
        :key="index"
        :id="item.code"
      >
        <div class="tabContainerList">
          <div
            class="tabContainerItem"
            v-for="(child, index) in articleList"
            :key="index"
            @click="linkTo(child.link || child.linkUrl)"
          >
            <img :src="child.imgUrl || child.img" alt="" />
            <div class="item-right">
              <p class="name">{{ child.title }}</p>
              <p class="description" v-if="child.description">
                {{ child.description }}
              </p>
              <p class="bottom">
                <span class="sp1" v-if="child.source"
                  >来源：{{ child.source }}</span
                >
                <span class="sp2">{{ child.publishTime }}</span>
              </p>
            </div>
          </div>
        </div>
      </mt-tab-container-item>
    </mt-tab-container>
    <van-pagination
      v-if="totalSize"
      v-model="PageIndex"
      @change="getList"
      force-ellipses
      :total-items="totalSize"
      :items-per-page="searchCondition.PageSize"
      :show-page-size="3"
    >
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
      <template #page="{ text }">{{ text }}</template>
    </van-pagination>
    <div class="noData" v-if="articleList.length == 0">
      <div class="imgBox">
        <img
          src="@/assets/img/noData.png"
          alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手"
          title="暂无数据"
        />
      </div>
      <p class="p1">暂无公告</p>
    </div>
  </div>
</template>
<script>
import {
  getArticleType,
  searchArticle,
  getHotArticle,
} from "@/api/information-sever";
export default {
  name: "informationCenter",
  data() {
    return {
      activeText: "全部文章",
      articleTypeList: [],
      articleList: [],
      searchCondition: {
        type: 0,
        tag: "",
        column: "",
        searchKey: "",
        sortField: "hot",
        PageSize: 8,
      },
      totalSize: 0,
      searchKey: "",
      PageIndex: 1,
    };
  },
  watch: {
    searchCondition: {
      handler(newV, oldV) {
        if (newV.column == "tag") {
          this.PageIndex = 1;
          this.getHotList();
        } else {
          this.PageIndex = 1;
          this.getList();
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getTabList("col");
    this.getList();
  },
  methods: {
    getTabList(type) {
      //获取文章类别
      let data = {
        category: type,
      };
      getArticleType(data).then((res) => {
        this.articleTypeList = res;
        this.articleTypeList.unshift({ code: "", text: "全部文章" });
      });
    },
    getList() {
      let data = {
        ...this.searchCondition,
        PageIndex: this.PageIndex,
      };
      searchArticle(data).then((res) => {
        this.totalSize = res.totalCount;
        this.articleList = res.items;
      });
    },
    getHotList() {
      getHotArticle().then((res) => {
        this.totalSize = 0;
        this.articleList = res;
      });
    },
    linkTo(link) {
      //跳转链接
      window.open(link);
    },
  },
};
</script>
<style lang="scss" scoped>
.informationCenter {
  padding-bottom: 0.6rem;
  .banner {
    width: 100%;
    height: 5rem;
    background: url("https://fs.zhbim.com/imgs/mobile/informationCenter-banner.png")
      no-repeat top center;
    background-size: 100% auto;
    padding: 1rem 0.3rem 0px;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .title {
      font-size: 0.42rem;
      font-weight: bold;
      margin-bottom: 0.29rem;
    }
    .text {
      font-size: 0.24rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .search-wrap {
      padding: 0.12rem 0 0.6rem 0;
      .mint-field {
        height: 0.8rem;
        background: #f4f5f9;
        border: 1px solid #ebebeb;
        border-radius: 0.4rem;
        ::v-deep .mint-field-core {
          background-color: #f4f5f9;
          color: #333;
        }
        ::v-deep .mint-cell-wrapper {
          padding: 0 0.34rem;
          font-size: 0.24rem;
        }
        ::v-deep .mint-field-clear {
          margin-right: 0.2rem;
        }
        .search-icon {
          width: 0.36rem;
          height: 0.36rem;
        }
      }
    }
  }
  .tabList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.1rem;
    border-bottom: 2px solid #e3e3e3;
    background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
    border-radius: 0.04rem;
    .tabItem {
      width: 1.45rem;
      height: 0.52rem;
      line-height: 0.52rem;
      text-align: center;
      font-size: 0.24rem;
      color: #666;
      &.active {
        background: #4a86ff;
        border-radius: 0.26rem;
        color: #fff;
      }
    }
  }
  .tagBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.28rem;
    font-size: 0.24rem;
    color: #999;
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .right {
      span.cur {
        color: #4a86ff;
      }
    }
  }
  .tabContainerList {
    .tabContainerItem {
      height: 2.2rem;
      border-bottom: 1px solid #ebebeb;
      padding: 0px 0.3rem;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      img {
        width: auto;
        height: 1.3rem;
        position: absolute;
        top: 50%;
        left: 0.3rem;
        transform: translateY(-50%);
      }
      .item-right {
        width: 100%;
        padding-left: 2.3rem;
        box-sizing: border-box;
        .name {
          font-size: 0.24rem;
          color: #333;
          font-weight: 500;
          padding-bottom: 0.1rem;
        }
        .description {
          font-size: 0.2rem;
          color: #666;
          font-weight: 500;
          padding-bottom: 0.2rem;
        }
        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 0.18rem;
            color: #999;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.van-pagination {
  margin-top: 0.38rem;
  ::v-deep .van-pagination__item {
    background-color: #f7f8f8;
  }
  ::v-deep .van-pagination__next,
  ::v-deep .van-pagination__prev {
    margin: 0 0.4rem;
  }
  ::v-deep .van-pagination__item--active {
    background-color: #1989fa;
  }
}
.noData {
  text-align: center;
  padding-top: 0.5rem;
  .imgBox {
    margin-bottom: 0.3rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .p1 {
    color: #333;
    font-size: 0.3rem;
    font-weight: 500;
  }
}
</style>
<style lang="scss">
.mint-field-clear {
  opacity: 1;
  i {
    color: #999;
  }
}
</style>