var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case-detail"},[_c('div',{staticClass:"brief-wrap"},[_c('img',{attrs:{"src":_vm.detail.img,"alt":_vm.detail.title,"title":_vm.detail.title}}),_c('img',{staticClass:"icon",attrs:{"src":"https://fs.zhbim.com/imgs/webPC/case/banner-icon.png","alt":"","title":""}}),_c('div',{staticClass:"textBox"},[_c('h4',{staticStyle:{"margin-top":"0px"}},[_vm._v(_vm._s(_vm.detail.title))]),_c('p',[_vm._v(_vm._s(_vm.detail.description))])])]),_c('div',{staticClass:"overview-wrap item-wrap",attrs:{"id":"overview"}},[_c('h4',[_vm._v("项目概况")]),_c('div',{staticClass:"line"}),_c('p',[_vm._v(" "+_vm._s(_vm.detail.survey)+" ")]),(_vm.detailImg)?_c('img',{staticClass:"detail-img",attrs:{"src":_vm.detail.detailImg,"alt":"项目概况","title":"项目概况"}}):_vm._e(),_c('div',{staticClass:"btn-wrap"},[(!_vm.detailImg)?_c('span',{staticClass:"look-detail",on:{"click":function($event){_vm.detailImg=true}}},[_vm._v("展开查看更多"),_c('img',{attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})]):_c('span',{staticClass:"look-detail",on:{"click":_vm.packUp}},[_vm._v("收起"),_c('img',{staticClass:"up-icon",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})])])]),_c('div',{staticClass:"modules-wrap item-wrap"},[_c('h4',[_vm._v("相关模块")]),_c('div',{staticClass:"line"}),_vm._l((_vm.detail.relevantModulars),function(item,index){return _c('a',{key:index,staticClass:"modules-list",attrs:{"href":`/productCenter?id=${item.productCode}&boxId=module`}},[_c('img',{staticClass:"icon",attrs:{"src":item.productCode == 'ZH.Plugin.General'
                ? 'https://fs.zhbim.com/imgs/webPC/home/synthesize.png'
                : item.productCode == 'ZH.Plugin.AEC'
                ? 'https://fs.zhbim.com/imgs/webPC/home/civil_engineering.png'
                : item.productCode == 'ZH.Plugin.MEP'
                ? 'https://fs.zhbim.com/imgs/webPC/home/electromechanical.png'
                : item.productCode == 'ZH.Plugin.Draw'
                ? 'https://fs.zhbim.com/imgs/webPC/home/plot.png'
                : 'https://fs.zhbim.com/imgs/yqby.png',"alt":"","title":""}}),_c('div',{staticClass:"text"},[_c('h5',[_vm._v(_vm._s(item.modularName)+"模块")]),_c('p',[_vm._v(_vm._s(item.modularDescription))])])])})],2),_c('div',{staticClass:"function-wrap item-wrap"},[_c('h4',[_vm._v("相关功能")]),_c('div',{staticClass:"line"}),_c('ul',{staticClass:"list"},[_vm._l((_vm.detail.functions),function(item,index){return [(index<_vm.showNum)?_c('li',{key:index,staticClass:"item"},[_c('a',{attrs:{"href":`/productCenter?id=${item.productCode}&boxId=module`}},[_c('img',{staticClass:"label",attrs:{"src":item.img,"alt":item.name,"title":item.name}}),_c('div',{staticClass:"text"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.description))])])])]):_vm._e()]})],2),(_vm.detail.functions.length>_vm.showNum)?_c('div',{staticClass:"look-more",on:{"click":function($event){_vm.showNum+=4}}},[_vm._v("展开查看更多"),_c('img',{attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }