<template>
  <van-overlay :show="showMask">
    <div v-if="state == 1">
      <div class="pcMask" v-html="text"></div>
      <div class="pcClose pcClose1" @click="clickMask">
        <van-icon name="cross"></van-icon>
      </div>
    </div>
    <div class="erweimaBox" v-else>
      <img src="https://fs.zhbim.com/imgs/erweima.jpg" />
      <p class="text">关注“BIM建模助手”服务号，立即参与活动</p>
      <div class="pcClose pcClose2" @click="clickMask">
        <van-icon name="cross"></van-icon>
      </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  name: "pcMask",
  props: {
    text: {
      type: String,
      default: "",
    },
    state: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      showMask: false,
    };
  },
  created() {},
  methods: {
    clickMask() {
      this.showMask = !this.showMask;
    },
  },
};
</script>
<style lang="scss" scoped>
.pcMask {
  width: 6.5rem;
  height: 6.1rem;
  background: url("https://fs.zhbim.com/imgs/mobile/pcmask.png") no-repeat top
    center;
  background-size: 100% auto;
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 0.3rem;
  color: #475670;
  font-weight: 500;
  transform: translate(-50%, -50%);
  padding-top: 4.6rem;
  text-align: center;
  line-height: 0.45rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.erweimaBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    margin: 0 auto;
  }
  .text {
    color: #fff;
    text-align: center;
    margin-top: 0.1rem;
    font-size: 0.28rem;
  }
}
.pcClose {
  position: fixed;
  left: 50%;
  width: 0.6rem;
  height: 0.6rem;
  text-align: center;
  line-height: 0.6rem;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  font-size: 0.32rem;
  font-weight: 500;
}
.pcClose1{
    top: 85%;
    transform: translate(-50%, -50%);
}
.pcClose2{
    top: 85%;
    margin-top: 1.6rem;
    transform: translate(-50%, -50%);
}
</style>