//关于活动模块接口

import request from '@/utils/request'

// 获取文章类别
export function getArticleType(params) {
  return request({
    url: 'config/article/category/get',
    method: 'GET',
    params
  })
}

// 文章搜索
export function searchArticle(params) {
  return request({
    url: 'config/article/search',
    method: 'GET',
    params
  })
}
// 获取热门文章
export function getHotArticle(params) {
  return request({
    url: 'config/article/hot',
    method: 'GET',
    params
  })
}
