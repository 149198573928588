<template>
  <div class="customer-wrap">
    <div class="banner">
      <h2>专业服务，快人一步</h2>
      <p>无论何时何地，产品经理就在您身边，助您提升产品使用效率</p>
    </div>
    <div class="online-wrap">
      <div class="online-item-wrap">
        <div class="online-item">
          <img class="online-img" src="https://fs.zhbim.com/imgs/mobile/customer/online-bg.png" alt="">
          <div class="description">
            <img src="https://fs.zhbim.com/imgs/mobile/customer/online_service_icon.png" alt="">
            <div class="right">
              <p>在线支持</p>
              <span>7X12获取专业工程师的帮助，快速解决您的 问题 </span>
            </div>
          </div>
          <mt-button type="primary" @click="onService">立即沟通</mt-button>
        </div>
      </div>
    </div>
    <ul class="advantage-list">
      <li class="item" v-for="item,index in list" :key="index">
        <img :src="item.icon" :alt="item.title" :title="item.title">
        <div class="right">
          <p>{{item.title}}</p>
          <span>{{item.des}}</span>
        </div>
      </li>
    </ul>
    <div class="bottom-wrap">
      <h3>专业服务，触手可及</h3>
      <p>BIM全栈工程师、职业产品经理为您提供服务保障</p>
      <a href="/FQA" class="link-item">
        <span>常见问题</span>
        <img src="https://fs.zhbim.com/imgs/mobile/customer/right-white.png" alt="">
      </a>
      <a href="/serviceAffiche" class="link-item">
        <span>服务公告</span>
        <img src="https://fs.zhbim.com/imgs/mobile/customer/right-white.png" alt="">
      </a>
    </div>
    <mt-popup v-model="popupVisible">
      <img style="width:5rem;height:5rem" src="https://fs.zhbim.com/imgs/activity_erweima.png" alt="">
    </mt-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      popupVisible: false,
      list: [
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/customer/document_icon.png',
          title: '帮助文档',
          des: '全面的产品指南助您轻松建模'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/customer/service_hotline_icon.png',
          title: '在线支持',
          des: '在线工程师助您高效解决问题'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/customer/hours_icon.png',
          title: '7X12小时工单',
          des: '工程师为您提供全天候工单服务'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/customer/service_icon.png',
          title: '7X12小时热线',
          des: '18924305303'
        }
      ]
    }
  },
  methods: {
    onService() {
      this.popupVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.customer-wrap {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/customer/customer-banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5rem;
    text-align: center;
    h2 {
      font-size: 0.42rem;
      font-weight: bold;
      color: #fff;
      line-height: 0.4rem;
      padding-top: 1.48rem;
      padding-bottom: 0.36rem;
    }
    p {
      margin: 0 auto;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.36rem;
    }
  }
  .online-wrap {
    position: relative;
    height: 6.4rem;
    .online-item-wrap{
      position: absolute;
      top: -1.2rem;
      left: 0;
      width: 100%;
      padding: 0 0.3rem;
      box-sizing: border-box;
    }
    .online-item {
      background: #ffffff;
      padding: 0.5rem;
      box-shadow: 0px 3px 6px 0px rgba(232, 235, 247, 0.86);
      .online-img {
        width: 100%;
      }
      .description {
        display: flex;
        margin-top: 0.45rem;
        margin-left: 0.1rem;
        margin-bottom: 0.2rem;
        img {
          width: 0.8rem;
          height: 0.85rem;
          margin-right: 0.3rem;
        }
        p {
          font-size: 0.3rem;
          font-weight: 500;
          color: #333333;
          margin-bottom: 0.1rem;
        }
        span {
          font-size: 0.24rem;
          font-weight: 500;
          color: #666666;
          line-height: 0.42rem;
        }
      }
      .mint-button--primary {
        background-color: #4a86ff;
        border-radius: 0;
        width: 100%;
        height: 0.64rem;
        line-height: 0.64rem;
        font-size: 0.24rem;
        color: #fff;
      }
    }
  }
  .advantage-list {
    padding: 0.4rem 0.3rem 0 0.3rem;
    .item {
      padding: 0.33rem;
      height: 1.6rem;
      box-sizing: border-box;
      background: #f3f7ff;
      margin-bottom: 0.42rem;
      display: flex;
      img {
        width: 0.62rem;
        height: 0.62rem;
        flex-shrink: 0;
        margin-right: 0.33rem;
      }
      p {
        font-size: 0.28rem;
        line-height: 0.28rem;
        font-weight: 500;
        color: #333333;
        margin-bottom: 0.2rem;
      }
      span {
        font-size: 0.24rem;
        font-weight: 500;
        color: #666666;
      }
    }
  }
  .bottom-wrap {
    background-color: #182030;
    text-align: center;
    h3 {
      padding-top: 0.57rem;
      font-size: 0.42rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.72rem;
    }
    p {
      font-size: 0.24rem;
      font-weight: 500;
      color: #999999;
      line-height: 0.54rem;
      margin-bottom: 0.82rem;
    }
    .link-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.46rem 0.32rem;
      border-top: #666666 1px solid;
      span {
        font-size: 0.3rem;
        font-weight: 500;
        color: #ffffff;
      }
      img {
        width: 0.18rem;
        height: 0.3rem;
      }
    }
  }
}
</style>