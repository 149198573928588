//鉴权的接口
import request from '@/utils/request'

//获取banner图列表
export function bannerList(params) {
  return request({
    url: 'config/banner/get',
    method: 'get',
    params
  })
}
// 获取模块功能
export function productFunc(params) {
  return request({
      url: 'product/func/get',
      method: 'GET',
      params
  })
}

// 获取最新资讯
export function productArticle(params) {
  return request({
      url: 'config/article/new',
      method: 'GET',
      params
  })
}

//记录模块点击日志
export function getCaseList(params) {
  return request({
      url: 'config/case/search',
      method: 'GET',
      params
  })
}