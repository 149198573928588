<template>
  <div class="activity-wrap">
    <div class="banner">
      <h2>最新活动</h2>
      <p>您可了解建模助手当前所有的优惠活动</p>
    </div>
    <ul class="activity-list">
      <template v-for="item,index in activeList">
        <li class="activity-item" v-if="index<showNum" :key="index" @click="activeLink(item)">
          <img class="main-img" :src="item.img" alt="">
          <div class="title-wrap">
            <div class="title">
              <h3>{{item.name}}</h3>
              <p>{{item.startTime}}-{{item.endTime}}</p>
            </div>
            <div class="status-wrap">
              <div class="item" v-if="item.status==='已结束'">
                <img src="https://fs.zhbim.com/imgs/webPC/active/status_end_icon.png" alt="">
                <span>已结束</span>
              </div>
              <div class="item" v-else>
                <img src="https://fs.zhbim.com/imgs/webPC/active/staus_proceed_icon.png" alt="">
                <span>进行中</span>
              </div>
            </div>
          </div>
          <div class="information-wrap">
            <div class="item period">
              <img src="https://fs.zhbim.com/imgs/webPC/active/time_icon.png" alt="">
              <span>{{item.day}}天</span>
            </div>
            <div class="item">
              <img src="https://fs.zhbim.com/imgs/webPC/active/look_icon.png" alt="">
              <span>{{item.browseCount}}</span>
            </div>
            <div class="item">
              <img src="https://fs.zhbim.com/imgs/webPC/active/user_icon.png" alt="">
              <span>{{item.memberCount}}</span>
            </div>
          </div>
        </li>
      </template>
      <div v-if="activeList.length>showNum" @click="showNum+=2" class="look-more">展开查看更多<img
          src="https://fs.zhbim.com/imgs/mobile/product/down.png">
      </div>
      <div v-else class="no-more">暂无更多活动</div>
    </ul>
  </div>
</template>
<script>
import { getActivityList } from "@/api/activity";
import { Toast } from 'mint-ui';
export default {
  name: 'activity',
  data() {
    return {
      activeList: [],
      showNum: 2
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getActivityList().then(res => {
        this.activeList = res.items
      })
    },
    activeLink(item) {
      if(item.status==='已结束'){
        Toast('活动已结束');
        return
      }
      if (!item.link) return
      if (item.link.indexOf('http') === -1) {
        this.$router.push({
          path: item.link
        })
      } else {
        window.open(item.link)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-wrap {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/activeBanner.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 6.3rem;
    text-align: center;
    h2 {
      font-size: 0.42rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.4rem;
      padding-top: 0.4rem;
      padding-bottom: 0.36rem;
    }
    p {
      margin: 0 auto;
      font-size: 0.24rem;
      color: #666666;
      line-height: 0.36rem;
    }
  }
  .activity-list {
    padding: 0.5rem 0.3rem;
    .activity-item {
      background: #ffffff;
      box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
      border-radius: 4px;
      margin-bottom: 0.32rem;
      .main-img {
        width: 100%;
        vertical-align: bottom;
        border-top-left-radius:4px;
        border-top-right-radius:4px;
      }
      .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.13rem 0;
        padding-left: 0.16rem;
        border-bottom: 1px solid #efefef;
        text-align: left;
        margin-top: 0.13rem;
        .title {
          h3 {
            font-size: 0.32rem;
            font-weight: 500;
            color: #333333;
          }
          p {
            font-size: 0.2rem;
            font-weight: 500;
            color: #999999;
          }
        }
        .status-wrap {
          .item {
            position: relative;
            img {
              width: 1.24rem;
            }
            span {
              display: inline-block;
              line-height: 0.5rem;
              position: absolute;
              top: 0;
              right: 0.18rem;
              font-size: 0.24rem;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }
      .information-wrap {
        padding: 0.26rem 0.15rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .item {
          font-size: 0.2rem;
          font-weight: 500;
          color: #999999;
          margin-right: 0.33rem;
          img {
            width: 0.24rem;
            vertical-align: middle;
            margin-right: 0.12rem;
          }
          span {
            display: inline-block;
            vertical-align: middle;
          }
        }
        .period {
          margin-right: auto;
        }
        .item:last-child {
          margin-right: 0;
        }
      }
    }
    .look-more {
      text-align: center;
      font-size: 0.28rem;
      font-weight: 500;
      color: #4a86ff;
       img {
        vertical-align: middle;
        width: 0.22rem;
        height: auto;
        margin-left: 0.1rem;
      }
    }
    .no-more{
        text-align: center;
        font-size: 0.28rem;
        font-weight: 500;
        color: #999;
      }
  }
}
</style>