<template>
  <div id="app">
    <Header v-if="source != 'wechat'"></Header>
    <div class="app-info">
      <router-view />
    </div>
    <Footer v-if="source != 'wechat'"></Footer>
    <return-to v-if="source != 'wechat'"></return-to>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import returnTo from "@/components/returnTo";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    returnTo,
  },
  data() {
    return {
      source: "",
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.linkParams) {
      this.source = this.$route.query.linkParams;
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
