import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Home from '@/views/home/index.vue'
import About from '@/views/about.vue'
import ProductCenter from '@/views/productCenter.vue'
import Case from '@/views/case/index.vue'
import CaseDetail from '@/views/case/caseDetail.vue'
import Education from '@/views/education.vue'
import Activity from '@/views/activity.vue'
import Customer from '@/views/customer.vue'
import FQA from '@/views/FQA.vue'
import ServiceAffiche from '@/views/serviceAffiche.vue'
import Solution from '@/views/solution/index.vue'
import SolutionGeneral from '@/views/solution/general.vue'
import SolutionAec from '@/views/solution/aec.vue'
import SolutionDraw from '@/views/solution/draw.vue'
import SolutionMep from '@/views/solution/mep.vue'
import Study from '@/views/study.vue'
import DocumentDetail from '@/views/documentDetail.vue'
import Responsive from '@/views/responsive.vue'
import InformationCenter from '@/views/informationCenter.vue'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 **/

const routes = [{
    path: '*/',
    name:'Home',
    redirect: '/'
  },
  {
    path: '/',
    component: Home,
    name:'Home',
    hidden: true
  },
  {
    path: '/About',
    component: About,
    name:'About',
    hidden: true
  },
  {
    path: '/productCenter',
    component: ProductCenter,
    name:'productCenter',
    hidden: true
  },
  {
    path: '/case',
    component: Case,
    name:'case',
    hidden: true
  },{ //案例详情
    path: '/caseDetail/:id',
    component: CaseDetail,
    name: 'caseDetail',
    hidden: true
  }
  ,{ //建模进修
    path: '/education',
    component: Education,
    name: 'education',
    hidden: true
  }
  ,{ //最新活动
    path: '/activity',
    component: Activity,
    name: 'activity',
    hidden: true
  },{ //解决方案
    path: '/solution',
    component: Solution,
    name: 'solution',
    hidden: true
  },{ //解决方案-综合模块
    path: '/solution/general',
    component: SolutionGeneral,
    name: 'solutionGeneral',
    hidden: true
  },{ //解决方案-机电模块
    path: '/solution/mep',
    component: SolutionMep,
    name: 'solutionMep',
    hidden: true
  },{ //解决方案-土建模块
    path: '/solution/aec',
    component: SolutionAec,
    name: 'solutionAec',
    hidden: true
  },{ //解决方案-出图模块
    path: '/solution/draw',
    component: SolutionDraw,
    name: 'solutionDraw',
    hidden: true
  }
  ,{ //客户支持
    path: '/customer',
    component: Customer,
    name: 'customer',
    hidden: true
  }
  ,{ //客户支持   常见问题
    path: '/FQA',
    component: FQA,
    name: 'FQA',
    hidden: true
  }
  ,{ //客户支持   服务公告
    path: '/serviceAffiche',
    component: ServiceAffiche,
    name: 'serviceAffiche',
    hidden: true
  }
  ,{ //学习教程  
    path: '/study',
    component: Study,
    name: 'study',
    hidden: true
  }
  ,{ //学习教程   文档详情
    path: '/documentDetail/:id',
    component: DocumentDetail,
    name: 'documentDetail',
    hidden: true
  },{ //资讯中心
    path: '/informationCenter',
    component: InformationCenter,
    name: 'informationCenter',
    hidden: true
  }
  ,{ //有求必应
    path: '/responsive',
    component: Responsive,
    name: 'responsive',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
