//关于产品模块接口

import request from '@/utils/request'

//获取产品列表
export function productSearch(params) {
  return request({
    url: 'product/search',
    method: 'get',
    params
  })
}

//获取产品购买时间
export function getProductTime(params) {
  return request({
    url: 'product/strategy',
    method: 'get',
    params
  })
}

//获取产品时间列表
export function getProductTimeList(params) {
  return request({
    url: 'product/strategy/list',
    method: 'get',
    params
  })
}

//获取产品详情
export function getProductDetail(params) {
  return request({
    url: 'product',
    method: 'get',
    params
  })
}

//购买接口
export function paymentOrder(data) {
  return request({
    url: 'order/purchase',
    method: 'post',
    data
  })
}

//查询订单状态
export function orderState(params) {
  return request({
    url: 'order/status',
    method: 'get',
    params
  })
}

//刷新支付二维码
export function refreshPayurl(data) {
  return request({
    url: 'order/payurl',
    method: 'POST',
    data
  })
}

//增加下载次数
export function productAddlno(data) {
  return request({
    url: 'product/adddlno',
    method: 'POST',
    data
  })
}

//获取产品功能模块
export function productFuncGet(params) {
  return request({
    url: 'product/func/get',
    method: 'GET',
    params
  })
}