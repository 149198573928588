<template>
  <div class="responsive-wrap">
    <div class="banner">
      <h2>有求必应</h2>
      <p>汇集知识碎片，解决技术难题</p>
    </div>
    <main>
      <div class="main-top">
        <h3>新增需求统计</h3>
        <p class="main-top-title">不断收集需求，解决一个个技术难题</p>
        <ul>
          <li :class="'item_'+index" v-for="(item, index) in courseAllList" :key="index">
            <a :href="`/study?moduleId=${item.category}`">
              <img :src="item.icon" :alt="item.label" />
              <div class="number">
                {{item.num}}+
              </div>
              <div class="title">
                <h5>{{item.label}}</h5>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="main-bottom">
        <h3>热门需求</h3>
        <p class="main-bottom-title">为您整理出我们收集到的热门需求，您可参考这些需求向我们提问</p>
        <ul>
          <li v-for="(item, index) in hotDemandList" :class="index%2===0?'left':'right'" :key="index">
            <template v-for="demandItem,demandIndex in item">
              <div class="demand-item" v-if="demandIndex<showNum" :key="demandIndex">
                <div class="item-top">
                  <h6>{{demandItem.title}}</h6>
                  <p>{{demandItem.description.substring(0,50)}}{{demandItem.description.length>50?'...':''}}</p>
                </div>
                <div class="item-bottom">
                  <div class="left">
                    <img :src="demandItem.avatar" alt="">
                    <span>{{demandItem.userName.substring(0,5)}}{{demandItem.userName.length>5?'...':''}}</span>
                  </div>
                  <div class="right" v-if="demandItem.description.length>50" @click="readMore(demandItem)">查看全文</div>
                </div>
              </div>
            </template>
          </li>
        </ul>
        <div v-if="hotDemandList.length&&hotDemandList[0].length>showNum" @click="showNum+=2" class="look-more">展开查看更多<img
            src="https://fs.zhbim.com/imgs/mobile/product/down.png">
        </div>
        <div v-else class="no-more">暂无更多热门需求</div>
      </div>
    </main>
    <mt-popup v-model="popupVisible">
      <div class="read-more-wrap">
        <div slot="title" class="title">
          <img class="head-portrait" :src="activeDemand.avatar" alt="">
          <span class="user-name">{{activeDemand.userName}}</span>
          <img @click="popupVisible=false" class="close" src="https://fs.zhbim.com/imgs/mobile/responsive/close.png" alt="">
        </div>
        <div class="main-content">
          <p>{{activeDemand.description}}</p>
          <div class="img-list" v-if="activeDemand.attachments&&activeDemand.attachments.length">
            <div class="img-item" @click="preview(index)" :class="{noMargin:(index+1)%5===0,noMarginBottom:index+1<6}"
              v-for="item,index in activeDemand.attachments" :key="index">
              <img :src="item" :alt="activeDemand.title" title="activeDemand.title">
            </div>
          </div>
        </div>
      </div>
    </mt-popup>
    <van-image-preview v-model="previewImg.show" :start-position="previewImg.index" :images="activeDemand.attachments"
      @change="onChange">
    </van-image-preview>
  </div>
</template>

<script>
import { resourceAllGet } from '@/api/resource'
import { getHotRequirements } from '@/api/responsive'
export default {
  data() {
    return {
      previewImg: {
        show: false,
        index: 0,
      },
      activeDemand: {},
      popupVisible: false,
      showNum: 2,
      courseAllList: [],
      courseList: [
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/curriculum_icon.png',
          title: '综合模块',
          num: 30,
          label: '综合类现有需求'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/civil_engineering.png',
          title: '土建模块',
          num: 10,
          label: '土建类现有需求'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/electromechanical_icon.png',
          title: '机电模块',
          num: 40,
          label: '机电类现有需求'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/plot_icon.png',
          title: '出图模块',
          num: 30,
          label: '出图类现有需求'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/demand_icon.png',
          title: '有求必应',
          num: 70,
          label: '其他类现有需求'
        },
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/build_icon.png',
          title: '施工模块',
          num: 10,
          label: '施工类现有需求',
        },
        {
          icon: 'https://fs.zhbim.com/imgs/webPC/study/familyLibrary_icon.png',
          title: '族库模块',
          num: 10,
          label: '族库类现有需求',
        },
      ],
      hotDemandList: [],
    }
  },
  created() {
    this.getAll()
    this.getHotDemandList()
  },
  methods: {
    getAll() {
      resourceAllGet().then((res) => {
        for (var i = 0; i < this.courseList.length; i++) {
          if (res[i] && res[i].category == 'ZH.Plugin.General') {
            this.courseAllList.push(Object.assign(this.courseList[0], res[i]))
          } else if (res[i] && res[i].category == 'ZH.Plugin.Draw') {
            this.courseAllList.push(Object.assign(this.courseList[3], res[i]))
          } else if (res[i] && res[i].category == 'ZH.Plugin.MEP') {
            this.courseAllList.push(Object.assign(this.courseList[2], res[i]))
          } else if (res[i] && res[i].category == 'ZH.Plugin.AEC') {
            this.courseAllList.push(Object.assign(this.courseList[1], res[i]))
          } else if (res[i] && res[i].category == 'ZH.Plugin.construction') {
            this.courseAllList.push(Object.assign(this.courseList[5], res[i]))
          }else if (res[i] && res[i].category == 'ZH.Plugin.Family') {
            this.courseAllList.push(Object.assign(this.courseList[6], res[i]))
          } else {
            this.courseAllList.push(Object.assign(this.courseList[4], res[i]))
          }
        }
      })
    },
    getHotDemandList() {
      this.hotDemandList[0] = []
      this.hotDemandList[1] = []
      getHotRequirements().then(res => {
        res.forEach((item, index) => {
          item.isShow = false;
          if (index % 2 === 0) {
            this.hotDemandList[0].push(item)
          } else if (index % 2 === 1) {
            this.hotDemandList[1].push(item)
          }
        })
        this.$forceUpdate()
        console.log(this.hotDemandList);
      })
    },
    //查看全文
    readMore(data) {
      console.log(data);
      this.activeDemand = data;
      this.popupVisible = true
    },
    onChange(index) {
      this.previewImg.index = index;
    },
    preview(index) {
      console.log(index);
      this.previewImg.index = index;
      this.previewImg.show = true
    },
  }
}
</script>
<style lang='scss' scoped>
.responsive-wrap {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/responsive/banner.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 6.3rem;
    text-align: center;
    h2 {
      font-size: 0.42rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.4rem;
      padding-top: 0.4rem;
      padding-bottom: 0.36rem;
    }
    p {
      margin: 0 auto;
      font-size: 0.24rem;
      color: #666666;
      line-height: 0.36rem;
    }
  }
  main {
    .main-top {
      text-align: center;
      h3 {
        font-size: 0.42rem;
        font-weight: 500;
        color: #333333;
        margin-top: 0.5rem;
        margin-bottom: 0.1rem;
      }
      p {
        font-size: 0.24rem;
        font-weight: 500;
        color: #666666;
        margin-bottom: 0.2rem;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
          padding: 0.2rem 0.3rem;
          img {
            width: 0.9rem;
          }
          .number {
            font-size: 0.28rem;
            font-weight: bold;
            color: #4a86ff;
          }
          h5 {
            font-size: 0.24rem;
            font-weight: 500;
            color: #333333;
          }
        }
        .item_3 {
          margin-left: 0.4rem;
        }
        .item_4 {
          margin-right: 0.4rem;
        }
        .item_5 {
          margin-left: 0.4rem;
        }
        .item_6 {
          margin-right: 0.4rem;
        }
      }
    }
    .main-bottom {
      h3 {
        text-align: center;
        font-size: 0.42rem;
        font-weight: 500;
        color: #333333;
        margin-top: 0.5rem;
        margin-bottom: 0.1rem;
      }
      .main-bottom-title {
        text-align: center;
        font-size: 0.24rem;
        font-weight: 500;
        color: #666666;
        margin-bottom: 0.2rem;
      }
      ul {
        // padding: 0 0.15rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        li {
          display: flex;
          flex-direction: column;
          justify-content: top;
          width: 50%;
          padding: 0 0.3rem;
          box-sizing: border-box;
          .demand-item {
            box-shadow: 0px 2px 6px 0px rgba(232, 235, 247, 0.6);
            border-radius: 4px;
            margin-bottom: 0.3rem;
          }
          .item-top {
            padding: 0.3rem 0.2rem;
            background-color: #f4f5f9;
            h6 {
              font-size: 0.24rem;
              font-weight: 500;
              color: #333333;
              margin-bottom: 0.2rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            p {
              font-size: 0.2rem;
              font-weight: 500;
              color: #666666;
              line-height: 0.28rem;
            }
          }
          .item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.18rem;
            font-weight: 500;
            color: #333333;
            line-height: 0.8rem;
            padding: 0 0.2rem;
            .left {
              img {
                width: 0.4rem;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 0.1rem;
              }
            }
            .right {
              color: #4a86ff;
            }
          }
        }
        .left {
          padding-right: 0.15rem;
        }
        .right {
          padding-left: 0.15rem;
        }
      }
      .look-more {
        text-align: center;
        font-size: 0.28rem;
        font-weight: 500;
        color: #4a86ff;
        padding: 0.6rem 0;
        img {
          vertical-align: middle;
          width: 0.22rem;
          height: auto;
          margin-left: 0.1rem;
        }
      }
      .no-more {
        padding: 0.6rem 0;
        text-align: center;
        font-size: 0.28rem;
        font-weight: 500;
        color: #999;
      }
    }
  }
  ::v-deep .mint-popup {
    width: 6.6rem;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(35, 55, 98, 0.3);
    border-radius: 8px;
    .read-more-wrap {
      padding: 0.32rem;
      .title {
        font-size: 0.24rem;
        color: #333;
        line-height: 0.4rem;
        margin-bottom: 0.2rem;
        .head-portrait {
          width: 0.4rem;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 0.1rem;
        }
        .close {
          float: right;
          width: 0.23rem;
          vertical-align: middle;
        }
      }
      .main-content {
        font-size: 0.26rem;
        font-weight: 500;
        color: #666666;
        line-height: 0.4rem;
        p {
          margin-bottom: 0.2rem;
          max-height: 4.67rem;
          overflow: auto;
        }
        .img-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .img-item {
            width: 30%;
            margin-bottom: 16;
            margin-right: 3%;
            img {
              width: 100%;
              height: 0.86rem;
            }
          }
        }
      }
    }
  }
  ::v-deep .van-image-preview {
    z-index: 2021 !important;
  }
  ::v-deep .van-overlay {
    z-index: 2020 !important;
  }
  //   ::v-deep .v-modal {
  //     z-index: 2022 !important;
  //   }
}
</style>