var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution solution-general"},[_vm._m(0),_c('div',{staticClass:"listBox"},[_c('div',{staticClass:"list"},_vm._l((_vm.tipList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des1))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des2))])])}),0)]),_c('div',{staticClass:"videoPd"},[_c('div',{staticClass:"videoBox"},[_c('video-item',{attrs:{"item":{
          path: 'https://fs.zhbim.com/imgs/webPC/solution/draw_video.mp4',
        },"pageName":"解决方案","showOfficial":false}})],1)]),_c('div',{staticClass:"main mainGenera"},[_c('p',{staticClass:"bigTitle"},[_vm._v("产品特性")]),_c('div',{staticClass:"mainGeneralList"},[_vm._m(1),_vm._m(2),(_vm.infoShow)?_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("预留预埋：管线开洞、标注、出图，一步到位")]),_vm._m(3),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/makemap3.jpg","alt":"出图模块","title":"预留预埋：管线开洞、标注、出图，一步到位"}})]):_vm._e(),(_vm.infoShow)?_c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("净高分析：净高分析、调整、出图，一步到位")]),_vm._m(4),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/makemap4.jpg","alt":"出图模块","title":"净高分析：净高分析、调整、出图，一步到位"}})]):_vm._e(),(_vm.infoShow)?_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("图纸类：完成Revit出图最后一公里")]),_vm._m(5),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/makemap5.jpg","alt":"出图模块","title":"图纸类：完成Revit出图最后一公里"}})]):_vm._e()])]),(!_vm.infoShow)?_c('div',{staticClass:"lookMore",on:{"click":function($event){return _vm.lookMore(3)}}},[_vm._v(" 展开查看更多 "),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})]):_vm._e(),_c('solutionCommon',{attrs:{"fqaList":_vm.fqaList,"dynamicList":_vm.dynamicList}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("建模助手——出图")]),_c('p',{staticClass:"text"},[_c('span',[_vm._v("Revit出图插件BIM工作中的最后一公里，")]),_c('br'),_vm._v("让Revit在操作中避免卡顿，最终效果的呈现可以节省客户看图与沟通的时间成本，将工作效率达到最大化 ")]),_c('a',{staticClass:"linkBtn",attrs:{"href":"/study?moduleId=ZH.Plugin.Draw"}},[_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"}}),_c('span',{staticClass:"name"},[_vm._v("产品进修")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("视图类：视图创建、调整、管理，全方位控制视图")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("构件显隐，避免卡顿，一键显示\\隐藏单个构件；")]),_c('p',{staticClass:"text1"},[_vm._v("局部三维、快速剖面、快速局部平面，视图一键创建；")]),_c('p',{staticClass:"text1"},[_vm._v("快速视图样板，一键应用、编辑、分享指定样板。")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/makemap1.jpg","alt":"出图模块","title":"视图类：视图创建、调整、管理，全方位控制视图"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("标注类：除去繁杂的设置，一键添加标注")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("管线的文字标注集中添加；")]),_c('p',{staticClass:"text1"},[_vm._v(" 轴网、构件尺寸定位快速创建、调整。 ")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/makemap2.jpg","alt":"出图模块","title":"标注类：除去繁杂的设置，一键添加标注"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("这里没什么好介绍的了，反正你们都懂的")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("这里也是，毕竟用我的人都是大触")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("这里没什么好介绍的了，反正你们都懂的")])])
}]

export { render, staticRenderFns }