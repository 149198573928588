<template>
  <div class="solution solution-general">
    <div class="banner">
      <p class="title">建模助手——机电</p>
      <p class="text">
        <span>Revit机电插件高效实现半自动化精准建模，</span
        ><br />基于Revit平台打造，方案施工指导性强，机电管线优化调整，达到快速深化模型
      </p>
      <a href="/study?moduleId=ZH.Plugin.MEP" class="linkBtn">
        <img
          src="https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"
          class="img"
        />
        <span class="name">产品进修</span>
      </a>
    </div>
    <div class="listBox">
      <div class="list">
        <div class="item" v-for="(item, index) in tipList" :key="index">
          <p class="p1">{{ item.title }}</p>
          <p class="p2">{{ item.des1 }}</p>
          <p class="p2">{{ item.des2 }}</p>
        </div>
      </div>
    </div>
    <div class="videoPd">
      <div class="videoBox">
        <video-item
          :item="{
            path: 'https://fs.zhbim.com/imgs/webPC/solution/mep_video.mp4',
          }"
          pageName="解决方案"
          :showOfficial="false"
        ></video-item>
      </div>
    </div>
    <div class="main mainGenera">
      <p class="bigTitle">产品特性</p>
      <div class="mainGeneralList">
        <div class="item">
          <p class="title">CAD图纸辨识：你知道建模可以多便捷吗？</p>
          <div class="textBox">
            <p class="text1">支持轴管道、立管 、喷淋、风管、桥架识别；</p>
            <p class="text1">更简洁的翻模流程识别→创建；</p>
            <p class="text1">高精确率与高识别率的双重选择。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/electromechanical1.jpg"
            alt="机电模块"
            title="CAD图纸辨识：你知道建模可以多便捷吗~"
            class="img"
          />
        </div>
        <div class="item item-color">
          <p class="title">管综精细深化：更快、更强、更高效。</p>
          <div class="textBox">
            <p class="text1">快速翻弯、管线排布快速管综；</p>
            <p class="text1">
              净高分析精准定位净高不足区域构件。
            </p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/electromechanical2.jpg"
            alt="机电模块"
            title="管综精细深化：更快、更强、更高效"
            class="img"
          />
        </div>
        <div class="item" v-if="infoShow">
          <p class="title">管线调整：来，试试看！</p>
          <div class="textBox">
            <p class="text1">快速打断、管线连接、碰头板下对齐、管线端点对齐、</p>
            <p class="text1">管线偏移对齐、支管升降等功能机电调整痛点全覆盖。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/electromechanical3.jpg"
            alt="机电模块"
            title="管线调整：来，试试看！"
            class="img"
          />
        </div>
        <div class="item item-color" v-if="infoShow">
          <p class="title">机电标准族：你想要的我都有</p>
          <div class="textBox">
            <p class="text1">水管附件集成（不断累积）</p>
            <p class="text1">风管附件集成（不断累积）</p>
            <p class="text1">电气配件集成（不断累积）</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/electromechanical4.jpg"
            alt="机电模块"
            title="机电标准族：你想要的我都有"
            class="img"
          />
        </div>
      </div>
    </div>
    <div class="lookMore" @click="lookMore(3)" v-if="!infoShow">
      展开查看更多
      <img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png"
        class="img"
      />
    </div>
    <solutionCommon
      :fqaList="fqaList"
      :dynamicList="dynamicList"
    ></solutionCommon>
  </div>
</template>
<script>
import videoItem from "@/components/videoItem";
import solutionCommon from "@/views/solution/common";
import { getFqaList } from "@/api/FQA";
export default {
  name: "general",
  data() {
    return {
      tipList: [
        {
          title: "新用户福利",
          des1: "注册即可免费体验",
          des2: "企业用户最高可获60天体验时长",
        },
        {
          title: "限时秒杀",
          des1: "1天仅需1元",
          des2: "只为让你提早下班",
        },
        {
          title: "有求必应",
          des1: "与你共同参与设计",
          des2: "快速响应 加量不加价",
        },
      ],
      infoShow: false,
      fqaList: [], //常见问题列表
      dynamicList: [
        {
          time: '2021-10',
          content: '新增【设备连接】可以快速将管道连接设备。',
        },
        {
          time: '2021-10',
          content: '新增【精准管线】识别CAD，精准生成管线。',
        },
        {
          time: '2021-10',
          content: '新增【风管连接】可以快速生成弯头、三通、四通连接。',
        },
        {
          time: '2021-10',
          content: '机电带多选的功能：全部支持右击执行完成框选',
        },
        {
          time: '2021-08',
          content: '机电三大附件、配件：增加41个常用族。',
        },
        {
          time: '2021-08',
          content: '翻模功能完善：立管、喷淋。',
        },
      ],
    };
  },
  components: {
    videoItem,
    solutionCommon,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getFqaList({ productCode: "ZH.Plugin.MEP" }).then((res) => {
        this.fqaList = res;
      });
    },
    lookMore(type) {
      if (type == 3) {
        this.infoShow = !this.infoShow;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.solution {
  .banner {
    background: url("https://fs.zhbim.com/imgs/mobile/solution/solution-mep-banner.png")
      no-repeat top;
    background-size: 100% 100%;
  }
}
</style>