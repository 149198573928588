<template>
  <div class="produce">
    <p class="title">性能强大、稳定、高效的BIM产品</p>
    <p class="text">经过多年技术沉淀，100+功能共筑建模助手产品矩阵</p>
    <a
      class="link"
      href="/productCenter"
      title="查看全部产品"
      alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手"
    >
      <span class="name">查看全部产品</span>
      <img
        src="https://fs.zhbim.com/imgs/mobile/home/next.png"
        alt=""
        title=""
        class="img"
      />
    </a>
    <van-collapse
      v-model="activeName"
      accordion
      class="list"
      @change="getData"
      id="ZH.Plugin.YQBY"
    >
      <van-collapse-item name="ZH.Plugin.YQBY" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/demand.png"
              alt=""
            />
            <p class="name">有求必应</p>
          </div>
        </template>
        <div class="info">
          <p class="name">自定义设计</p>
          <p class="text">
            需求定制 快速响应<br />
            功能自定义添加 加量不加价
          </p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.YQBY&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.YQBY'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.YQBY&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部有求必应功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="ZH.Plugin.General" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/synthesize.png"
              alt=""
            />
            <p class="name">综合模块</p>
          </div>
        </template>
        <div class="info">
          <p class="name">界面自定义</p>
          <p class="text">
            Revit高效建模，拒绝等待<br />
            快速 精准 便捷
          </p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.General&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.General'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.General&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部综合模块功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="ZH.Plugin.AEC" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/civil_engineering.png"
              alt=""
            />
            <p class="name">土建模块</p>
          </div>
        </template>
        <div class="info">
          <p class="name">一键解放生产力</p>
          <p class="text">
            0基础小白也适用<br />
            批量 精细 一步到位
          </p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.AEC&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.AEC'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.AEC&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部土建模块功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="ZH.Plugin.MEP" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/electromechanical.png"
              alt=""
            />
            <p class="name">机电模块</p>
          </div>
        </template>
        <div class="info">
          <p class="name">快速进行管线综</p>
          <p class="text">
            高度本土化管线综合调整<br />
            省时 省心 省力
          </p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.MEP&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.MEP'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.MEP&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部机电模块功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="ZH.Plugin.Draw" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/plot.png"
              alt=""
            />
            <p class="name">出图模块</p>
          </div>
        </template>
        <div class="info">
          <p class="name">出图标准化</p>
          <p class="text">
            解决模型到出图最后一公里<br />
            更快 更高 更强
          </p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.Draw&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.Draw'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.Draw&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部出图模块功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="ZH.Plugin.construction" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/build.png"
              alt=""
            />
            <p class="name">施工模块</p>
          </div>
        </template>
        <div class="info">
          <p class="name">施工进度模拟</p>
          <p class="text">施工阶段 应建尽建</p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.construction&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.construction'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.construction&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部施工模块功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item name="ZH.Plugin.Family" class="item">
        <template #title>
          <div class="tabTop">
            <img
              class="icon"
              src="https://fs.zhbim.com/imgs/webPC/home/familyLibrary.png"
              alt=""
            />
            <p class="name">族库模块</p>
          </div>
        </template>
        <div class="info">
          <p class="name">一体化信息模型构件管理</p>
          <p class="text">便捷的Revit族获取、使用、管理 <br />全方位解决方案</p>
          <div class="btnBox">
            <div class="link active" @click="tryOut()">立即试用</div>
            <a
              href="/productCenter?id=ZH.Plugin.Family&boxId=module"
              class="link"
              >产品介绍</a
            >
          </div>
          <div class="childList" v-if="activeName == 'ZH.Plugin.Family'">
            <div
              class="childItem"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div class="childSmall">
                <p class="p1">{{ index + 1 }}-{{ item.name }}</p>
                <p class="p2">{{ item.description }}</p>
              </div>
            </div>
            <div class="childItem">
              <a
                href="/productCenter?id=ZH.Plugin.Family&boxId=module"
                class="childSmall"
              >
                <p class="p1">全部族库模块功能</p>
                <p class="p2">查看更多>></p>
              </a>
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <pcmask text="请前往pc端下载中心下载使用<br/>（网址：https://www.zhbim.com/）" ref="pcmask"></pcmask>
  </div>
</template>

<script>
import pcmask from "@/components/pcmask.vue";
import { productFunc } from "@/api/home";
export default {
  name: "produce",
  data() {
    return {
      activeName: "ZH.Plugin.YQBY",
      detailList: [],
    };
  },
  components: {
    pcmask,
  },
  mounted() {
    this.getData("ZH.Plugin.YQBY", 1);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    async getData(type, state) {
      this.detailList = await productFunc({
        productCode: type,
        needCategory: false,
        pageIndex: 1,
        pageSize: 8,
      });
      if (!state) {
        var topH1 = document.getElementById("ZH.Plugin.YQBY");
        topH1.scrollIntoView(true);
        window.scrollBy(0, -120);
      }
    },
    handleScroll() {
      console.log("0000");
    },
    tryOut() {
      this.$refs.pcmask.clickMask();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";
.produce {
  text-align: center;
  padding-top: 0.6rem;
  .title {
    font-size: 0.36rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.2rem;
  }
  .text {
    font-size: 0.24rem;
    font-weight: 500;
    color: #666;
  }
  .link {
    font-size: 0.24rem;
    color: #4a86ff;
    padding-top: 0.35rem;
    .name,
    .img {
      display: inline-block;
      vertical-align: middle;
    }
    .img {
      width: 0.22rem;
      height: auto;
      margin-left: 0.12rem;
    }
  }
  .list {
    margin-top: 0.42rem;
    background-color: #f1f6fd;
    .item {
      .tabTop {
        text-align: left;
        .icon,
        .name {
          display: inline-block;
          vertical-align: middle;
        }
        .icon {
          width: 0.8rem;
          height: auto;
        }
        .name {
          font-size: 0.3rem;
          color: #333;
          margin-left: 0.22rem;
        }
      }
      .info {
        padding-top: 0.3rem;
        .name {
          display: inline-block;
          font-size: 0.36rem;
          font-weight: bold;
          color: #333;
          border-bottom: 0.04rem solid #4a86ff;
          padding-bottom: 0.1rem;
        }
        .text {
          font-size: 0.2rem;
          color: #9babc3;
          font-weight: 500;
          margin-top: 0.1rem;
          margin-bottom: 0.3rem;
        }
        .btnBox {
          .link {
            padding-top: 0px;
            display: inline-block;
            width: 2.1rem;
            height: 0.64rem;
            text-align: center;
            line-height: 0.64rem;
            border: 1px solid #4a5e76;
            font-size: 0.24rem;
            color: #4a5e76;
            font-weight: 500;
            margin: 0px 0.27rem;
            cursor: pointer;
            &.active {
              background: #4a86ff;
              color: #fff;
              border-color: #4a86ff;
            }
          }
        }
        .childList {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 0.53rem;
          .childItem {
            width: 50%;
            padding: 0px 0.1rem;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            .childSmall {
              background-color: #edf3fc;
              border: 1px solid #bdcef0;
              padding: 0.25rem 0.3rem 0.2rem;
              box-sizing: border-box;
              -webkit-box-sizing: border-box;
              margin-bottom: 0.13rem;
              width: 100%;
              display: inline-block;
            }
            .p1 {
              font-size: 0.24rem;
              color: #677687;
              font-weight: 500;
              margin-bottom: 0.1rem;
            }
            .p2 {
              font-size: 0.2rem;
              color: #252627;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.produce {
  .list {
    .item {
      .van-collapse-item__content {
        background-color: transparent;
      }
      .van-collapse-item__title--expanded {
        background: linear-gradient(0deg, #e1efff 0%, #f5f9ff 100%);
        box-shadow: 0px 16px 24px 0px rgba(226, 231, 251, 0.5);
        border-radius: 0.02rem;
        -webkit-border-radius: 0.02rem;
        .tabTop {
          .name {
            color: #4a86ff;
          }
        }
        .van-icon {
          color: #4a86ff;
        }
      }
      .van-cell {
        padding: 0px 0.3rem;
        height: 1rem;
        line-height: 1rem;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }
      .van-icon {
        height: 1rem;
        line-height: 1rem;
      }
    }
  }
}
</style>
