var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution solution-general"},[_vm._m(0),_c('div',{staticClass:"listBox"},[_c('div',{staticClass:"list"},_vm._l((_vm.tipList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des1))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des2))])])}),0)]),_c('div',{staticClass:"videoPd"},[_c('div',{staticClass:"videoBox"},[_c('video-item',{attrs:{"item":{
          path: 'https://fs.zhbim.com/imgs/webPC/solution/mep_video.mp4',
        },"pageName":"解决方案","showOfficial":false}})],1)]),_c('div',{staticClass:"main mainGenera"},[_c('p',{staticClass:"bigTitle"},[_vm._v("产品特性")]),_c('div',{staticClass:"mainGeneralList"},[_vm._m(1),_vm._m(2),(_vm.infoShow)?_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("管线调整：来，试试看！")]),_vm._m(3),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/electromechanical3.jpg","alt":"机电模块","title":"管线调整：来，试试看！"}})]):_vm._e(),(_vm.infoShow)?_c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("机电标准族：你想要的我都有")]),_vm._m(4),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/electromechanical4.jpg","alt":"机电模块","title":"机电标准族：你想要的我都有"}})]):_vm._e()])]),(!_vm.infoShow)?_c('div',{staticClass:"lookMore",on:{"click":function($event){return _vm.lookMore(3)}}},[_vm._v(" 展开查看更多 "),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})]):_vm._e(),_c('solutionCommon',{attrs:{"fqaList":_vm.fqaList,"dynamicList":_vm.dynamicList}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("建模助手——机电")]),_c('p',{staticClass:"text"},[_c('span',[_vm._v("Revit机电插件高效实现半自动化精准建模，")]),_c('br'),_vm._v("基于Revit平台打造，方案施工指导性强，机电管线优化调整，达到快速深化模型 ")]),_c('a',{staticClass:"linkBtn",attrs:{"href":"/study?moduleId=ZH.Plugin.MEP"}},[_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"}}),_c('span',{staticClass:"name"},[_vm._v("产品进修")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("CAD图纸辨识：你知道建模可以多便捷吗？")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("支持轴管道、立管 、喷淋、风管、桥架识别；")]),_c('p',{staticClass:"text1"},[_vm._v("更简洁的翻模流程识别→创建；")]),_c('p',{staticClass:"text1"},[_vm._v("高精确率与高识别率的双重选择。")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/electromechanical1.jpg","alt":"机电模块","title":"CAD图纸辨识：你知道建模可以多便捷吗~"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("管综精细深化：更快、更强、更高效。")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("快速翻弯、管线排布快速管综；")]),_c('p',{staticClass:"text1"},[_vm._v(" 净高分析精准定位净高不足区域构件。 ")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/electromechanical2.jpg","alt":"机电模块","title":"管综精细深化：更快、更强、更高效"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("快速打断、管线连接、碰头板下对齐、管线端点对齐、")]),_c('p',{staticClass:"text1"},[_vm._v("管线偏移对齐、支管升降等功能机电调整痛点全覆盖。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("水管附件集成（不断累积）")]),_c('p',{staticClass:"text1"},[_vm._v("风管附件集成（不断累积）")]),_c('p',{staticClass:"text1"},[_vm._v("电气配件集成（不断累积）")])])
}]

export { render, staticRenderFns }