<template>
  <div class="solution-common">
    <div class="main mainGenera1">
      <p class="bigTitle">产品动态</p>
      <div class="mainGeneralList">
        <template v-for="(item, index) in dynamicList">
          <div class="item" :key="index" v-if="!productShow && index > 2?false:true">
            <p class="time title">{{ item.time }}</p>
            <p class="text">{{ item.content }}</p>
          </div>
        </template>
      </div>
    </div>
    <div class="lookMore" @click="lookMore(1)" v-if="!productShow">
      展开查看更多
      <img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png"
        class="img"
      />
    </div>
    <div class="border"></div>
    <div class="main mainGenera2">
      <p class="bigTitle">常见问题</p>
      <van-collapse class="genera2list" v-model="activeName" accordion>
        <template v-for="(item, index) in fqaList">
          <van-collapse-item
            :name="item.key"
            :key="index"
            :title="item.question"
            v-if="!qrShow && index > 2?false:true"
          >
            <div class="info">{{ item.answer }}</div>
          </van-collapse-item>
        </template>
      </van-collapse>
    </div>
    <div class="lookMore" @click="lookMore(2)" v-if="!qrShow">
      展开查看更多
      <img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png"
        class="img"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "solutionCommon",
  props: {
    dynamicList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fqaList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeName: "",
      productShow: false,
      qrShow: false,
    };
  },
  methods: {
    lookMore(type) {
      if (type == 1) {
        this.productShow = !this.productShow;
      } else {
        this.qrShow = !this.qrShow;
      }
    },
  },
};
</script>
