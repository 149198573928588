<template>
  <div class="service-affiche">
    <div class="banner">
      <h2>服务公告</h2>
    </div>
    <main>
      <div class="search-wrap">
        <mt-field placeholder="请输入您想搜索的内容" v-model="searchKey">
          <img @click="searchCondition.searchKey=searchKey" class="search-icon"
            src="https://fs.zhbim.com/imgs/mobile/customer/search-icon.png" height="45px" width="100px">
        </mt-field>
      </div>
      <div class="affiche-wrap">
        <ul class="tab-list">
          <li class="tab-item" :class="{active:searchCondition.tag===item.code}" @click="searchCondition.tag=item.code"
            v-for="item,index in afficheTypeList" :key="index">{{item.text}}</li>
        </ul>
        <ul class="list">
          <li class="item" v-for="item,index in afficheList" :key="index">
            <a :href="item.linkUrl" target="_blank">
              <span>{{item.publishTime}}</span>
              <p>{{item.title}}</p>
            </a>
          </li>
        </ul>
        <van-pagination v-if="totalSize" v-model="PageIndex" @change="searchArticle" :items-per-page="searchCondition.PageSize"
          force-ellipses :total-items="totalSize" :show-page-size="3">
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
        <div class="noData" v-if="afficheList.length==0">
          <div class="imgBox">
            <img src="@/assets/img/noData.png" alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手" title="暂无数据" />
          </div>
          <p class="p1">暂无公告</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getArticleType, searchArticle } from '@/api/information-sever'
export default {
  name: 'serviceAffiche',
  data() {
    return {
      currentPage: 1,
      totalSize: 0,
      active: 1,
      searchCondition: {
        type: 1,
        tag: '',
        searchKey: '',
        PageSize: 8,
      },
      PageIndex: 1,
      searchKey: '',
      afficheTypeList: [],
      afficheList: []
    }
  },
  created() {
    this.getArticleType()
    this.searchArticle()
  },
  watch: {
    searchCondition: {
      handler(newV, oldV) {
        this.PageIndex = 1
        this.searchArticle()
      },
      deep: true
    },
    searchKey(newKey, oldKey) {
      if (!newKey) {
        this.searchCondition.searchKey = newKey
      }
    }
  },
  methods: {
    handleCurrentChange(page) {
      this.PageIndex = page
      this.searchArticle()
    },
    // 获取文章类别  tag：普通文章标签，col：普通文章栏目，sa：服务公告类别 *必填
    getArticleType() {
      let data = {
        category: 'sa'
      }
      getArticleType(data).then(res => {
        this.afficheTypeList = res
        this.afficheTypeList.unshift({ code: '', text: '全部' })
      })
    },
    // 搜索文章
    async searchArticle() {
      let data = {
        ...this.searchCondition,
        PageIndex: this.PageIndex
      }
      let result = await searchArticle(data);
      this.totalSize = result.totalCount
      this.afficheList = result.items
    },
  }
}
</script>
<style lang='scss' scoped>
.service-affiche {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/customer/service-banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 2.9rem;
    text-align: center;
    padding: 0 0.3rem;
    position: relative;
    h2 {
      position: absolute;
      top: 50%;
      margin-top: -0.21rem;
      left: 0.3rem;
      font-size: 0.42rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.42rem;
    }
  }
  main {
    padding: 0.6rem 0.3rem;
    .search-wrap {
      padding: 0.12rem 0 0.6rem 0;
      .mint-field {
        height: 0.8rem;
        background: #f4f5f9;
        border: 1px solid #ebebeb;
        border-radius: 0.4rem;
        ::v-deep .mint-field-core {
          background-color: #f4f5f9;
        }
        ::v-deep .mint-cell-wrapper {
          padding: 0 0.34rem;
          font-size: 0.24rem;
        }
        ::v-deep .mint-field-clear {
          margin-right: 0.2rem;
        }
        .search-icon {
          width: 0.36rem;
          height: 0.36rem;
        }
      }
    }
    .affiche-wrap {
      .tab-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.24rem;
        font-weight: 500;
        color: #666666;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #ebebeb;
        li {
          line-height: 0.54rem;
          padding: 0 0.28rem;
          border-radius: 0.26rem;
        }
        .active {
          background-color: #4a86ff;
          color: #fff;
        }
      }
      .list {
        .item {
          padding: 0.28rem 0;
          border-bottom: 1px solid #ebebeb;
          span {
            font-size: 0.2rem;
            font-weight: bold;
            color: #999999;
            display: inline-block;
            margin-bottom: 0.1rem;
          }
          p {
            font-size: 0.24rem;
            font-weight: 500;
            color: #333333;
          }
        }
      }
      .van-pagination {
        margin-top: 0.38rem;
        ::v-deep .van-pagination__item {
          background-color: #f7f8f8;
        }
        ::v-deep .van-pagination__next,
        ::v-deep .van-pagination__prev {
          margin: 0 0.4rem;
        }
        ::v-deep .van-pagination__item--active {
          background-color: #1989fa;
        }
      }
      .noData {
        text-align: center;
        padding-top: 0.5rem;
        .imgBox {
          margin-bottom: 0.3rem;
          img {
            width: 100%;
            height: auto;
          }
        }
        .p1 {
          color: #333;
          font-size: 0.3rem;
          font-weight: 500;
        }
      }
    }
  }
}
</style>