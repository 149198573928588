var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution solution-general"},[_vm._m(0),_c('div',{staticClass:"listBox"},[_c('div',{staticClass:"list"},_vm._l((_vm.tipList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des1))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des2))])])}),0)]),_c('div',{staticClass:"videoPd"},[_c('div',{staticClass:"videoBox"},[_c('video-item',{attrs:{"item":{
          path: 'https://fs.zhbim.com/imgs/webPC/solution/general_video.mp4',
        },"pageName":"建模进修","showOfficial":false}})],1)]),_c('div',{staticClass:"main mainGenera"},[_c('p',{staticClass:"bigTitle"},[_vm._v("产品特性")]),_c('div',{staticClass:"mainGeneralList"},[_vm._m(1),_vm._m(2),(_vm.infoShow)?_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("三维、剖面随心创：你想怎么剖我都不反抗")]),_vm._m(3),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize3.gif","alt":"综合模块","title":"三维、剖面随心创：你想怎么剖我都不反抗"}})]):_vm._e(),(_vm.infoShow)?_c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("链接模型：效率，效率，还是效率！")]),_vm._m(4),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize4.gif","alt":"综合模块","title":"链接模型：效率，效率，还是效率"}})]):_vm._e(),(_vm.infoShow)?_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("视图操控：所见即所得")]),_vm._m(5),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize5.gif","alt":"综合模块","title":"视图操控：所见即所得"}})]):_vm._e(),(_vm.infoShow)?_c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("功能尝新，脑洞大开：你敢用我敢做")]),_vm._m(6),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize6.gif","alt":"综合模块","title":"功能尝新，脑洞大开：你敢用我敢做"}})]):_vm._e()])]),(!_vm.infoShow)?_c('div',{staticClass:"lookMore",on:{"click":function($event){return _vm.lookMore(3)}}},[_vm._v(" 展开查看更多 "),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})]):_vm._e(),_c('solutionCommon',{attrs:{"fqaList":_vm.fqaList,"dynamicList":_vm.dynamicList}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("建模助手——综合")]),_c('p',{staticClass:"text"},[_c('span',[_vm._v("让Revit操作起来更便捷，更具人性化，")]),_vm._v("真正帮助BIM工作者在Revit的插件中 运用中做到一模多用，用得更顺手、用得更开心，提高工作效率 ")]),_c('a',{staticClass:"linkBtn",attrs:{"href":"/study?moduleId=ZH.Plugin.General"}},[_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"}}),_c('span',{staticClass:"name"},[_vm._v("产品进修")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("界面自定义：只看你用的功能")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("选项卡随心显示隐藏；")]),_c('p',{staticClass:"text1"},[_vm._v("经典背景色（黑/白）快速来回切换；")]),_c('p',{staticClass:"text1"},[_vm._v("一键多屏扩展，建模无边界；")]),_c('p',{staticClass:"text1"},[_vm._v("快速常用文件夹，WINDOWS的也能管一管。")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize1.gif","alt":"综合模块","title":"界面自定义：只看你用的功能"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("便捷管理轻松建模：我只关心你的效率")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("项目族统一管理，标准分类，")]),_c('p',{staticClass:"text1"},[_vm._v("创建、修改、删除一步到位；")]),_c('p',{staticClass:"text1"},[_vm._v("标高管理，快速精准创建标高。")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize2.gif","alt":"综合模块","title":"便捷管理轻松建模：我只关心你的效率"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("框选、点选生成局部三维，还可以记录、定位问题；")]),_c('p',{staticClass:"text1"},[_vm._v("横着剖、竖着剖、斜着剖，快速生成剖面。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("批量链接，一次链“10”个模型；")]),_c('p',{staticClass:"text1"},[_vm._v("编辑链接，跨界添加、删除链接中的构件；")]),_c('p',{staticClass:"text1"},[_vm._v("打开链接，双开Revit打开链接模型。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("实时识别当前视图过滤器，操作可见性；")]),_c('p',{staticClass:"text1"},[_vm._v("类别组合，一键控制常用类别显示/隐藏；")]),_c('p',{staticClass:"text1"},[_vm._v("元素快速着色，点哪哪出彩。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("通用放置，快速创建构件、变更构件类型；")]),_c('p',{staticClass:"text1"},[_vm._v("图转文字，识别CAD文字赋值给构件属性；")]),_c('p',{staticClass:"text1"},[_vm._v("两点对齐，任意两个构件上的两点高度对齐。")])])
}]

export { render, staticRenderFns }