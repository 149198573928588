<template>
  <div class="about">
    <div class="banner">
      <h2>中恒信息科技</h2>
      <p>每个BIMer都应该拥有一个建模助手</p>
    </div>
    <!-- 关于我们 -->
    <div class="about-us main-item">
      <div class="title-wrap">
        <div class="title">
          <h3>关于我们</h3>
          <img src="https://fs.zhbim.com/imgs/mobile/home/case_dip.png" alt="">
        </div>
        <p class="title-English">About us</p>
      </div>
      <div class="about-us-list">
        <div class="subhead">
          <h4><i></i>选择建模助手</h4>
        </div>
        <div class="about-us-item">
          <div class="item-title">
            <h5>企业优势</h5>
            <p>Enterprise advantage</p>
          </div>
          <ul class="list">
            <li class="item-wrap" :class="index % 2 === 0 ? 'left' : 'right'" v-for="item, index in advantageList" :key="index">
              <div class="item">
                <img :src="item.img" alt="">
                <p>{{ item.label }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="about-us-item qualification">
          <div class="item-title">
            <h5>行业贡献</h5>
            <p>Industry contribution</p>
          </div>
          <ul class="qualification-list">
            <li class="qualification-item">
              <div class="img-wrap">
                <div class="img-top">
                  <img src="https://fs.zhbim.com/imgs/mobile/about/vision-line.png" alt="">
                  <span>公司介绍</span>
                </div>
                <img src="https://fs.zhbim.com/imgs/mobile/about/vision.png" alt="">
              </div>
              <div class="text-wrap">
                <p class="text1">目前BIM插件市场客户普遍靠以下几种手段解决需求</p>
                <p class="text1">1、购买少量平台，交替使用。（未能全员普及，效率低）</p>
                <p class="text1">2、购买盗版产品。（虽价格低，但无保障，有一定风险）</p>
                <p class="text1">3、注册更多试用账号，延长试用期。（操作繁琐，不长久）</p>
                <p class="text1">
                  建模助手拥有行业领先的实施应用团队对落地需求的挖掘与优化。研发产品负责人拥有5年设计、2年研发、2年BIM类Revit与CAD二开经验，对行业有深度认知，对产品方向有准确定位。研发技术负责人拥有14年研发经验，对开发各种技术架构，产品架构均有涉猎，且参与过GIS类平台研发，具有较深的行业资历。
                  ——中恒信息科技（广州）有限公司：“不会做建模的产品经理，不是好BIMer”</p>
              </div>
            </li>
            <li class="qualification-item qualification-right">
              <div class="text-wrap">
                <p class="text">1.中国图学学会举办的 “龙图杯”全国BIM(建筑信息模型)大赛，曾荣获一等奖、二等奖、优秀奖</p>
                <p class="text">2.中国勘察设计协会举办的 “创新杯”建筑信息模型(BIM)应用大赛，曾荣获三等奖</p>
                <p class="text">3.中国建筑业协会举办的“中国建设工程BIM大赛”，曾荣获一类成果奖</p>
                <p class="text">4.型建香港(bsHK)举办的第五届国际BIM大奖赛，曾荣获最佳医院项目BIM应用奖</p>
              </div>
              <div class="img-wrap">
                <div class="img-top">
                  <img src="https://fs.zhbim.com/imgs/mobile/about/awards-line.png" alt="">
                  <span>斩获奖项</span>
                </div>
                <img src="https://fs.zhbim.com/imgs/mobile/about/awards.png" alt="">
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 专业团队 -->
    <div class="professional-team main-item">
      <div class="title-wrap">
        <div class="title">
          <h3>专业团队</h3>
          <img src="https://fs.zhbim.com/imgs/mobile/home/case_dip.png" alt="">
        </div>
        <p class="title-English">Professional team</p>
        <h4>与数万开发者共享技术、商业创新</h4>
      </div>
      <div class="professional-team-item">
        <h5>开发者中心</h5>
        <p>汇聚精品内容，云集技术大咖，关注二开技术，让热爱和<br />成长从这里开始</p>
        <img class="bg" src="https://fs.zhbim.com/imgs/mobile/about/developer-center.png" alt="">
      </div>
      <div class="item-tip">
        <swiper class="swiper" ref="swiper" :options="swiperOption">
          <swiper-slide v-for="item, index in professionalList" :key="index">
            <div class="item">
              <div class="title">
                <img :src="item.icon" alt="">
                <h6>{{ item.name }}</h6>
              </div>
              <ul>
                <li v-for="itemTxt, indexTxt in item.list" :key="indexTxt"><span>{{ itemTxt.keyPoint }}</span>
                  <p>{{ itemTxt.text }}</p>
                </li>
              </ul>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="relation-us main-item" id="relation">
      <div class="title-wrap">
        <div class="title">
          <h3>联系我们</h3>
          <img src="https://fs.zhbim.com/imgs/mobile/home/case_dip.png" alt="">
        </div>
        <p class="title-English">contact us</p>
      </div>
      <div class="relation-us-list">
        <div class="subhead">
          <h4><i></i>联系方式</h4>
        </div>
        <ul>
          <li class="item">
            <div class="left">
              <img src="https://fs.zhbim.com/imgs/mobile/about/service.png" alt="">
              <p> 客户服务</p>
            </div>
            <div class="right">
              <p class="name"> 建模助手</p>
              <p>【电话】18924305303</p>
              <p>【邮箱】3561153013@qq.com</p>
            </div>
          </li>
          <li class="item">
            <div class="left">
              <img src="https://fs.zhbim.com/imgs/mobile/about/product-manager.png" alt="">
              <p>关注或联系我们</p>
            </div>
            <div class="right">
              <p class="tip">添加建模助手公众号、企业微信，即可了解最新动态</p>
              <div class="erweima">
                <img src="https://fs.zhbim.com/imgs/erweima.jpg" alt="">
                <img src="https://fs.zhbim.com/imgs/erweima.jpg" alt="">
              </div>
              <p class="tip">咨询热线：18924305303</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 地图 -->
    <div class="map-wrap">
      <about-map></about-map>
    </div>
  </div>
</template>

<script>
import aboutMap from "@/components/aboutMap";
export default {
  name: 'about',
  components: {
    aboutMap
  },
  data() {
    return {
      swiperOption: {
        loop: true, // 循环模式选项
        loopedSlides: 3,
      },
      advantageList: [
        {
          img: 'https://fs.zhbim.com/imgs/mobile/about/safety.png',
          label: '安全可信的高效软件'
        },
        {
          img: 'https://fs.zhbim.com/imgs/mobile/about/team.png',
          label: '专业的研发团队'
        },
        {
          img: 'https://fs.zhbim.com/imgs/mobile/about/scene.png',
          label: '全栈全场景的内部体验'
        },
        {
          img: 'https://fs.zhbim.com/imgs/mobile/about/after-sale.png',
          label: '贴心的售后服务'
        }
      ],
      professionalList: [
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/about/team-icon.png',
          name: 'BIM团队',
          list: [
            {
              keyPoint: '【资质】',
              text: '多年行业打磨，大奖拿到手软'
            }, {
              keyPoint: '【效率】',
              text: '自给自足插件，技术支持高效办公'
            }, {
              keyPoint: '【认证】',
              text: '多个机构客户肯定，revit插件手到擒来'
            }
          ]
        },
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/about/course-icon.png',
          name: '教程与测试',
          list: [
            {
              keyPoint: '【课程】',
              text: '专业团队视频录制，适用任何新手入门'
            }, {
              keyPoint: '【测试】',
              text: '在线调优测试，体验revit实测'
            }, {
              keyPoint: '【小白】',
              text: '文档傻瓜式教学，手把手教你快速上手'
            }
          ]
        },
        {
          icon: 'https://fs.zhbim.com/imgs/mobile/about/exploit-icon.png',
          name: '研发组',
          list: [
            {
              keyPoint: '【行业】',
              text: 'BIM资深二开成员，深度了解用户痛点'
            }, {
              keyPoint: '【敏捷】',
              text: '漏洞修复率极高，市场容错率极低'
            }, {
              keyPoint: '【认证】',
              text: '全面了解二开技术，掌握python、C#、API技术'
            }
          ]
        }
      ],
      // relationList: [
      //   {
      //     icon: 'https://fs.zhbim.com/imgs/mobile/about/product-manager.png',
      //     position: '关注或联系我们',
      //     name: '梁先生',
      //     phone: '',
      //     WeChat: 'lss12905',
      //     email: '824662708@qq.com'
      //   }, {
      //     icon: 'https://fs.zhbim.com/imgs/mobile/about/service.png',
      //     position: '客户服务',
      //     name: ' 建模助手',
      //     phone: '18924305303',
      //     email: '3561153013@qq.com'
      //   }, {
      //     icon: 'https://fs.zhbim.com/imgs/mobile/about/operation.png',
      //     position: '运营负责人',
      //     name: '兰先生',
      //     phone: '18924305303',
      //     email: '3561153013@qq.com'
      //   },
      // ]
    };
  },
  watch: {
    $route(val, old) {
      if (this.$route.query.boxId) {
        this.$nextTick(() => {
          document.querySelector('#relation').scrollIntoView(true)
          window.scrollBy(0, -120)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.boxId) {
      document.querySelector('#relation').scrollIntoView(true)
      window.scrollBy(0, -120)
    }
  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";

.about {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/about/banner.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 3.5rem;
    text-align: center;

    h2 {
      font-size: 0.48rem;
      font-weight: bold;
      color: #fff;
      line-height: 0.4rem;
      padding-top: 1.35rem;
      padding-bottom: 0.3rem;
    }

    p {
      margin: 0 auto;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.24rem;
    }
  }

  .main-item {
    .title-wrap {
      padding-top: 0.6rem;
      padding-bottom: 0.24rem;

      .title {
        display: flex;
        align-items: center;

        h3 {
          font-size: 0.36rem;
          font-weight: 500;
          color: #4a86ff;
          margin-right: 0.1rem;
          line-height: 0.36rem;
        }

        img {
          width: 0.24rem;
          height: 0.2rem;
        }
      }

      .title-English {
        font-size: 0.3rem;
        font-weight: 500;
        color: #4a86ff;
        opacity: 0.2;
        line-height: 0.3rem;
      }
    }
  }

  .about-us {
    padding: 0 0.3rem;
    width: 100%;
    box-sizing: border-box;

    .about-us-list {
      .subhead {
        margin-bottom: 0.4rem;

        h4 {
          font-size: 0.3rem;
          font-weight: 500;
          color: #333333;
          line-height: 0.3rem;

          i {
            display: inline-block;
            width: 0.04rem;
            height: 0.3rem;
            background: #4a86ff;
            vertical-align: middle;
            margin-right: 4px;
          }
        }
      }

      .about-us-item {
        // width: 6.3rem;
        border: 1px solid #9aa5c4;
        background: linear-gradient(0deg, #f3f5fa 0%, #ffffff 100%);
        box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
        border-radius: 4px;
        padding: 0.3rem 0.3rem;
        margin-bottom: 0.32rem;

        .item-title {
          text-align: center;
          position: relative;
          height: 0.67rem;
          margin-bottom: 0.4rem;

          h5 {
            font-size: 0.36rem;
            font-weight: bold;
            color: #333333;
            line-height: 0.54rem;
          }

          p {
            position: absolute;
            top: 6px;
            left: 50%;
            margin-left: -2.1rem;
            font-size: 0.43rem;
            font-weight: 500;
            color: #999999;
            opacity: 0.18;
          }
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          .item-wrap {
            width: 50%;
            box-sizing: border-box;
          }

          .left {
            padding-right: 0.15rem;
          }

          .right {
            padding-left: 0.15rem;
          }

          .item {
            border: 1px solid #8c95b0;
            margin-bottom: 0.3rem;
            text-align: center;

            img {
              width: 1.46rem;
              height: 1.46rem;
              margin-top: 0.55rem;
              vertical-align: bottom;
            }

            p {
              font-size: 0.24rem;
              font-weight: 500;
              color: #8c95b0;
              margin-top: 0.35rem;
              padding-bottom: 0.35rem;
              line-height: 0.24rem;
            }
          }
        }
      }

      .qualification {
        // width: 6.6rem;
        padding-top: 0.6rem;
        padding-left: 0.15rem;
        padding-right: 0.15rem;

        .item-title {
          margin-bottom: 0.4rem;
        }
      }

      .qualification-list {
        .qualification-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.2rem;

          .img-wrap {
            margin-right: 0.25rem;

            img {
              width: 2.7rem;
              height: auto;
              vertical-align: bottom;
            }

            .img-top {
              position: relative;
              margin-bottom: 0.6rem;

              span {
                color: #4a86ff;
                font-size: 0.24rem;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -0.3rem;
                margin-left: -0.98rem;
                text-align: center;
                display: inline-block;
                width: 1.96rem;
                height: 0.6rem;
                line-height: 0.6rem;
                background: linear-gradient(0deg, #e1efff 0%, #f5f9ff 100%);
              }
            }
          }

          .text-wrap {
            font-size: 0.24rem;
            color: #666;
            line-height: 0.36rem;

            .text {
              margin-bottom: 0.4rem;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                color: #4a86ff;
              }
            }

            .text1 {
              margin-bottom: 0.2rem;
            }
          }
        }

        .qualification-right {
          margin-bottom: 0.24rem;

          .img-wrap {
            margin-right: 0;
            margin-left: 0.25rem;

            .img-top {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .professional-team {
    padding-top: 0.3rem;

    .title-wrap {
      padding: 0 0.3rem;

      h4 {
        font-size: 0.3rem;
        font-weight: 500;
        color: #333333;
        line-height: 0.78rem;
      }
    }

    .professional-team-item {
      background-color: #f4f5f9;

      h5 {
        font-size: 0.48rem;
        color: #333333;
        text-align: center;
        padding-top: 0.36rem;
        margin-bottom: 0.1rem;
      }

      p {
        font-size: 0.24rem;
        color: #666666;
        text-align: center;
        margin-bottom: 0.15rem;
      }

      .bg {
        width: 6.34rem;
        margin-left: 0.32rem;
      }
    }

    .item-tip {
      width: 100%;
      height: 2.9rem;
      position: relative;

      .swiper-container {
        overflow: visible !important;
        width: 5.8rem !important;
        height: 2.7rem;
        box-sizing: border-box;
        position: absolute;
        top: -0.56rem;
        left: 0;

        .swiper-wrapper {
          .swiper-slide {
            width: 5.8rem !important;
            height: 2.9rem;
            box-sizing: border-box;
            background: #fcfdff;
            border: 2px solid #d7e1f3;
            box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
            transform: translate3d(0.5rem, 0px, 0px);
            padding: 0.33rem 0.2rem;

            .item {
              .title {
                display: flex;
                margin-bottom: 0.1rem;

                img {
                  width: 0.4rem;
                  height: 0.4rem;
                  margin-right: 0.08rem;
                }

                h6 {
                  font-size: 0.3rem;
                  font-weight: bold;
                  color: #4a86ff;
                }
              }

              ul {
                margin-top: 0.2rem;

                li {
                  display: flex;
                  font-size: 0.24rem;
                  line-height: 0.3rem;
                  color: #666;
                  margin-bottom: 0.22rem;

                  span {
                    color: #333;
                    flex-shrink: 0;
                  }
                }
              }
            }
          }

          .swiper-slide-prev {
            margin-top: 0.24rem;
            height: 2.7rem !important;
          }

          .swiper-slide-next {
            margin-top: 0.24rem;
            height: 2.7rem !important;
          }

          .swiper-slide-active {
            margin-right: 0.3rem;
            margin-left: 0.3rem;
          }
        }
      }
    }
  }

  .relation-us {
    padding: 0 0.3rem;
    width: 100%;
    box-sizing: border-box;

    .relation-us-list {
      .subhead {
        margin-bottom: 0.4rem;

        h4 {
          font-size: 0.3rem;
          font-weight: 500;
          color: #333333;
          line-height: 0.3rem;

          i {
            display: inline-block;
            width: 0.04rem;
            height: 0.3rem;
            background: #4a86ff;
            vertical-align: middle;
            margin-right: 4px;
          }
        }
      }

      ul {
        .item {
          // width: 6.9rem;
          // height: 2rem;
          box-sizing: border-box;
          background: #fcfdff;
          border: 1px solid #d7e1f3;
          box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
          display: flex;
          align-items: center;
          padding: 0.35rem 0.44rem;
          margin-bottom: 0.2rem;

          .left {
            text-align: center;
            margin-right: 0.4rem;
            width: 2rem;

            img {
              width: 0.85rem;
              height: 0.85rem;
            }

            p {
              width: 2rem;
              font-size: 0.28rem;
              font-weight: 500;
              color: #90b5ff;
            }
          }

          .right {
            font-size: 0.24rem;
            font-weight: 400;
            color: #333333;
            line-height: 0.46rem;

            .name {
              font-weight: 500;
              margin-left: 4px;
            }
            .erweima{
              margin: 0.1rem 0px;
              img{
                width: 1rem;
                margin-right: 0.3rem;
              }
            }
          }
        }
      }
    }
  }

  .map-wrap {
    padding-top: 0.26rem;
    position: relative;
  }
}</style>
