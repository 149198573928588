<template>
  <div class="home-classicCase">
    <div class="top">
      <div class="title">
        <span>项目案例</span
        ><img
          src="https://fs.zhbim.com/imgs/mobile/home/case_dip.png"
          class="dip"
        />
      </div>
      <p class="smallTitle">Project case</p>
    </div>
    <p class="suom">
      中恒信息科技围绕工程建设全生命周期业务，立足BIM、移动互联网、物联网、大数据等技术，
      为政府、业主、施工、咨询、设计、和运营等参建方提供完善的服务体系。
    </p>
    <mt-swipe :auto="4000" class="banner">
      <template v-for="(item, index) in caseList">
        <mt-swipe-item
          :key="index"
          @click.native="linkTo(item)"
        >
          <div class="bannerItem">
            <img
              :src="item.img"
              :alt="item.title"
              :title="item.title"
              class="img"
            />
            <p class="title">{{ item.title }}</p>
            <p class="description">{{ item.description }}</p>
            <div class="difficulty">
              <p class="name">项目难点:</p>
              <div class="list">
                <template
                  v-for="(
                    difficultyItem, difficultyIndex
                  ) in item.caseDifficultys"
                >
                  <p
                    class="item"
                    :key="difficultyIndex"
                    v-if="difficultyIndex < 3"
                  >
                    <img :src="difficultyItem.img" alt="" />
                    <span class="sp">{{ difficultyItem.difficulty }}</span>
                  </p>
                </template>
              </div>
            </div>
          </div>
        </mt-swipe-item>
      </template>
    </mt-swipe>
    <a href="/case" class="link"
      >更多案例<img src="https://fs.zhbim.com/imgs/mobile/home/next.png"
    /></a>
  </div>
</template>
<script>
import { getCaseList } from "@/api/home";
export default {
  data() {
    return {
      caseList: [],
    };
  },
  created() {
    this.getCase();
  },
  methods: {
    async getCase() {
      await getCaseList({
        PageIndex:1,
        PageSize:5
      }).then((res) => {
        this.caseList = res.items;
      });
    },
    linkTo(item) {
      this.$router.push({
        path: `/caseDetail/${item.id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-classicCase {
  padding: 0rem 0.3rem 0.43rem;
  background: url("https://fs.zhbim.com/imgs/mobile/home/case_bg.png") no-repeat
    top;
  background-size: 100% auto;
  padding-top: 0.4rem;
  .top {
    .title {
      span,
      .dip {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        font-size: 0.36rem;
        font-weight: 500;
        color: #4a86ff;
        font-family: PingFang SC;
      }
      .dip {
        width: 0.24rem;
        height: auto;
        margin-right: 0.08rem;
      }
    }
    .smallTitle {
      font-size: 0.3rem;
      color: #4a86ff;
      font-weight: 500;
      opacity: 0.2;
      font-family: PingFang SC;
      margin-top: -0.2rem;
    }
  }
  .suom {
    font-size: 0.2rem;
    font-weight: 500;
    color: #7081b2;
    margin-top: 0.16rem;
    opacity: 0.9;
  }
  .banner {
    width: 100%;
    height: 10rem;
    margin-top: 0.28rem;
    .bannerItem {
      width: 100%;
      height: 9.3rem;
      padding: 0.17rem 0.5rem 0.12rem;
      border: 0.02rem solid #ffffff;
      background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
      box-shadow: 0.05rem 0.03rem 0.06rem 0px rgba(232, 235, 247, 0.86);
      border-radius: 0.04rem;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      .img {
        width: 100%;
        height: 3.52rem;
        margin-bottom: 0.35rem;
      }
      .title {
        font-size: 0.32rem;
        color: #333;
        font-weight: bold;
      }
      .description {
        font-size: 0.24rem;
        color: #666;
        font-weight: 500;
        margin-top: 0.21rem;
      }
      .difficulty {
        margin-top: 0.39rem;
        .name {
          font-size: 0.24rem;
          color: #333;
          font-weight: bold;
        }
        .list {
          .item {
            margin-top: 0.18rem;
            position: relative;
            img,
            .sp {
              display: inline-block;
              vertical-align: middle;
            }
            img {
              width: 0.35rem;
              height: auto;
              position: absolute;
              top: 50%;
              left: 0px;
              transform: translateY(-50%);
            }
            .sp {
              width: 100%;
              font-size: 0.24rem;
              color: #666;
              font-weight: 500;
              margin-left: 0.1rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-left: 0.4rem;
              box-sizing: border-box;
              -webkit-box-sizing: border-box;
            }
          }
        }
      }
    }
  }
  .link {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 0.24rem;
    color: #4a86ff;
    font-weight: 500;
    img {
      width: 0.22rem;
      height: auto;
      margin-left: 0.12rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss">
.home-classicCase {
  .mint-swipe-indicators {
    width: 100%;
    text-align: center;
  }
}
</style>