<template>
  <div class="product-center">
    <div class="banner">
      <div class="contain">
        <h2>建模助手产品</h2>
        <p>
          从基础功能到行业应用领域，建模助手提供完善的产品体系，助力您的业务腾飞
        </p>
      </div>
    </div>
    <div class="banner-tip">
      <swiper class="swiper" v-if="product.length>1" ref="swiper" :options="swiperOption">
        <swiper-slide>
          <div class="item">
            <h4>
              <img src="https://fs.zhbim.com/imgs/webPC/product/product_hot.png" alt="热门产品" />热门产品
            </h4>
            <ul class="item-bottom">
              <li v-for="(item, index) in product" :key="index">
                <a :href="`/study?moduleId=${item.code}`" v-if="index < 4">
                  <h5>{{ item.name }}模块</h5>
                  <p>{{ item.description }}</p>
                </a>
              </li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item middle">
            <h4>
              <img src="https://fs.zhbim.com/imgs/webPC/product/product_event.png" alt="产品大事件" />产品大事件
            </h4>
            <ul class="item-bottom">
              <li v-for="(item, index) in events" :key="index">
                <a :href="`/study?keyword=${item.title}`">
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.detail }}</p>
                </a>
              </li>
            </ul>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item last">
            <div class="last-top">
              <h4>
                <img src="https://fs.zhbim.com/imgs/webPC/product/product_demand.png" alt="有求必应" />有求必应
              </h4>
              <ul class="item-bottom">
                <li v-for="(item, index) in demand" :key="index">
                  <a :href="`/study?moduleId=ZH.Plugin.YQBY&keyword=${item.name}`">
                    <p><span></span> {{ item.detail }}</p>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <div class="line"></div>
            <div class="btn-wrap">
              <span class="left">免费试用<img src="https://fs.zhbim.com/imgs/webPC/product/product_skip_icon.png" alt="免费试用" />
              </span>
              <span @click="demandSub">
                需求提报<img src="https://fs.zhbim.com/imgs/webPC/product/product_skip_icon.png" alt="需求提报" />
              </span>
            </div> -->
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="free-trial" @click="onPcmask">
      免费试用 >>
    </div>
    <div class="module-wrap" id="module">
      <div class="module-title">
        <div class="module-title-wrap">
          <span>{{moduleName}}</span>
          <img @click="showTitleList=!showTitleList" class="right" src="https://fs.zhbim.com/imgs/mobile/home/catalog.png" alt="">
        </div>
        <ul v-show="showTitleList" class="title-list">
          <li @click="getModule(item.code)" :class="activeCode===item.code?'active':''" v-for="(item, index) in product"
            :key="index">
            <p v-if="item.code != 'ZH.Plugin.PluginSprite'">{{item.code != 'ZH.Plugin.YQBY' ? item.name + '模块' : item.name}}</p>
          </li>
        </ul>
      </div>
      <div class="fun-item" :class="'fun-item-'+(index+1)%3" v-for="(item, index) in modules" :key="index">
        <h5><span></span>{{ item.category }}</h5>
        <ul>
          <template v-for="(child, childIndex) in item.functions">
            <li v-if="childIndex<item.showNum" :key="childIndex">
              <a :href="`/study?moduleId=${child.productCode}&keyword=${child.name}`">
                <img :src="child.img" :alt="child.name">
                <h6>{{ child.name }}</h6>
                <span>{{ child.description }}</span>
              </a>
            </li>
          </template>
        </ul>
        <div v-if="item.functions.length>item.showNum" @click="lookMore(index)" class="look-more">展开查看更多<img
            src="https://fs.zhbim.com/imgs/mobile/product/down.png">
        </div>
      </div>
    </div>
    <div class="product-bottom">
      <div class="bottom-border">
        <div class="experience-wrap">
          <h4>开始体验免费套餐</h4>
          <p>注册即可免费体验建模助手产品，为您提供便捷优质的建模实践机会。<br />企业用户最高可获得60天体验时长。</p>
          <mt-button type="primary" @click="onPcmask">开始体验</mt-button>
        </div>
        <div class="relation-wrap">
          <h4>联系我们</h4>
          <p>我们为您提供个性化的售前购买咨询服务，以及全面的技术售后服务。</p>
          <mt-button type="default" @click="toLink">联系我们</mt-button>
        </div>
      </div>
    </div>
    <pcmask :text="maskTxT" ref="pcmask"></pcmask>
  </div>
</template>
<script>

import { productSearch, productFuncGet } from '@/api/product'
import pcmask from '@/components/pcmask.vue';
export default {
  name: 'productCenter',
  data() {
    return {
      activeCode: 'ZH.Plugin.General',
      maskTxT:'请前往pc端下载中心下载使用<br/>（网址：https://www.zhbim.com/）',
      showTitleList: false,
      showFunList: false,
      swiperOption: {
        loop: true, // 循环模式选项
        loopedSlides: 3,
      },
      product: [],
      modules: [],
      demand: [
        //有求必应
        {
          name: '视图范围',
          detail: '【视图范围】上线：滑动条实时调整视图范围',
        },
        {
          name: '万能标注',
          detail: '【万能标注】上线：快速对构件进行尺寸标注',
        },
        {
          name: '软管连接',
          detail: '【软管连接】上线：BIM审查批量设备连接神器',
        },
        {
          name: '快速幕墙',
          detail: '【快速幕墙】上线：一键随意生成不同间隔的幕墙网格',
        },
        {
          name: '视图命名',
          detail: '【视图命名】完善：增加复制新视图并修改命名功能',
        }
      ],
      events: [
        {
          title: '万能刷',
          detail: '根据选择的构件信息，快速赋值给其它的构件',
        },
        {
          title: '精准生梁',
          detail: '识别CAD，精准生成梁',
        },
        {
          title: '设备连接',
          detail: '可以快速将管道连接设备',
        },
        {
          title: '一键开洞',
          detail: '存在部分模块无法生成洞口的问题',
        },
      ],
    }
  },
  components:{
    pcmask
  },
  computed: {
    moduleName() {
      for (let i = 0; i < this.product.length; i++) {
        const element = this.product[i];
        if (this.activeCode === element.code) {
          let name = ''
          return name = this.activeCode != 'ZH.Plugin.YQBY' ? element.name + '模块' : element.name
        }
      }
    }
  },
  watch: {
    $route(val, old) {
      if (this.$route.query.boxId) {
        this.$nextTick(() => {
          document.querySelector('#module').scrollIntoView(true)
          window.scrollBy(0, -120)
        })
      }
      if (this.$route.query.id) {
        this.activeCode = this.$route.query.id
        this.asyncData(this.$route.query.id)
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.activeCode = this.$route.query.id
    }
    this.getProduct()
    this.asyncData(this.$route.query.id)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    if (this.$route.query.boxId) {
      document.querySelector('#module').scrollIntoView(true)
      window.scrollBy(0, -120)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    getProduct() {
      productSearch().then((res) => {
        this.product = res.items
      })
    },
    async asyncData(moduleId) {
      let modules = await productFuncGet({
        ProductCode: moduleId || this.activeCode,
        NeedCategory: true,
      })
      this.modules = modules
      this.modules.forEach((item, index) => {
        this.$set(this.modules[index], 'showNum', 6);
      })

    },
    getModule(code) {
      this.showTitleList = false
      this.$router.push({
        path: `/productCenter?id=${code}&timestamp=${new Date().getTime()}&boxId=module`,
      })
    },
    lookMore(index) {
      this.modules[index].showNum = this.modules[index].showNum + 6
      console.log(this.modules);
    },
    handleScroll() {
      if (this.showTitleList) {
        this.showTitleList = false
      }
    },
    onPcmask(){
      this.maskTxT="请前往pc端下载中心下载使用<br/>（网址：https://www.zhbim.com/）"
      this.$refs.pcmask.clickMask();
    },
    toLink(){
      this.$router.push({path:'/about?boxId=relation'})
    },
    demandSub(){
      this.maskTxT="请这位优秀的建模人移步到<br/>公众号留言提需求哈~"
      this.$refs.pcmask.clickMask();
    }
  }
}
</script>
<style lang="scss" scoped>
.product-center {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/product/product-banner.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 6.3rem;
    text-align: center;
    h2 {
      font-size: 0.42rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.4rem;
      padding-top: 0.4rem;
      padding-bottom: 0.36rem;
    }
    p {
      margin: 0 auto;
      width: 4.78rem;
      font-size: 0.24rem;
      color: #666666;
      line-height: 0.36rem;
    }
  }
  .banner-tip {
    width: 100%;
    height: 4.73rem;
    position: relative;
    .swiper-container {
      overflow: visible !important;
      width: 5.8rem !important;
      height: 5.44rem;
      box-sizing: border-box;
      position: absolute;
      top: -0.71rem;
      left: 0;
      .swiper-wrapper {
        .swiper-slide {
          width: 5.8rem !important;
          height: 5.44rem;
          box-sizing: border-box;
          background-color: #ffffff;
          border: 2px solid #d7e1f3;
          box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
          transform: translate3d(0.5rem, 0px, 0px);
          padding: 0.28rem 0.16rem;
          .item {
            h4 {
              font-size: 0.3rem;
              font-weight: bold;
              color: #4a86ff;
              margin-bottom: 0.12rem;
              padding-left: 0.3rem;
              img {
                width: 0.5rem;
                // height: 0.5rem;
                vertical-align: bottom;
                margin-right:0.1rem;
              }
            }
            .item-bottom {
              padding-left: 0.3rem;
              li {
                padding: 0.16rem 0;
                h5 {
                  font-size: 0.28rem;
                  color: #333333;
                }
                p {
                  font-size: 0.24rem;
                  color: #666666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .line {
              margin-left: 0.3rem;
              height: 2px;
              background-color: #d2d2d2;
            }
            .btn-wrap {
              margin-top: 0.3rem;
              span {
                display: block;
                text-align: center;
                font-size: 0.28rem;
                color: #333333;
                img {
                  margin-left: 0.12rem;
                }
              }
            }
          }
        }
        .swiper-slide-prev {
          margin-top: 0.24rem;
          height: 5.23rem !important;
        }
        .swiper-slide-next {
          margin-top: 0.24rem;
          height: 5.23rem !important;
        }
        .swiper-slide-active {
          margin-right: 0.3rem;
          margin-left: 0.3rem;
        }
      }
    }
  }
  .free-trial {
    text-align: center;
    color: #4a86ff;
    line-height: 0.98rem;
  }
  .module-wrap {
    .module-title {
      position: relative;
      .module-title-wrap {
        padding: 0.26rem 0.33rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #ffffff;
        background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
        box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
        span {
          font-size: 0.3rem;
          line-height: 0.3rem;
          color: #4a86ff;
        }
        img {
          width: 0.34rem;
          height: 0.34rem;
        }
      }
      ul {
        position: absolute;
        top: 1rem;
        left: 0;
        width: 100%;
        background: #f7f7fb;
        box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
        opacity: 0.9;
        border-radius: 2px;
        z-index: 99;
        padding: 0 0.33rem;
        li {
          padding: 0.25rem 0;
          font-size: 0.3rem;
          color: #666666;
        }
        .active {
          color: #4a86ff;
        }
      }
    }
    .fun-item {
      padding: 0 0.35rem;
      h5 {
        font-size: 0.3rem;
        color: #333333;
        font-weight: 500;
        padding: 0.6rem 0;
        span {
          display: inline-block;
          width: 2px;
          height: 0.3rem;
          background: #4a86ff;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 3rem;
          margin-bottom: 0.6rem;
          img {
            width: 0.32rem;
            height: auto;
            margin-bottom: 0.2rem;
          }
          h6 {
            font-size: 0.24rem;
            color: #333;
            font-weight: bold;
          }
          span {
            font-size: 0.24rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.34rem;
          }
        }
      }
      .look-more {
        text-align: center;
        font-size: 0.28rem;
        font-weight: 500;
        color: #4a86ff;
        padding-bottom: 0.55rem;
        img {
          vertical-align: middle;
          width: 0.22rem;
          height: auto;
          margin-left: 0.1rem;
        }
      }
    }
    .fun-item-1 {
      background-color: #fdf8f8;
    }
    .fun-item-2 {
      background-color: #ffffff;
    }
    .fun-item-0 {
      background-color: #f9fbff;
    }
  }
  .product-bottom {
    background-color: #333333;
    color: #f9fbff;
    padding: 0.4rem 0.3rem;
    .bottom-border {
      border: 2px solid #fffbfc;
      padding: 0.4rem 0.2rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 5.65rem;
      box-sizing: border-box;
      h4 {
        font-size: 0.3rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
      }
      p {
        font-size: 0.18rem;
        font-weight: 500;
        line-height: 0.34rem;
        margin-bottom: 0.3rem;
      }
      .mint-button {
        background-color: #f6f9fe;
        border-radius: 0;
        width: 2.1rem;
        height: 0.64rem;
        line-height: 0.64rem;
        font-size: 0.24rem;
        color: #4a86ff;
      }
      .mint-button--primary {
        background-color: #4a86ff;
        border-radius: 0;
        width: 2.1rem;
        height: 0.64rem;
        line-height: 0.64rem;
        font-size: 0.24rem;
        color: #fff;
      }
    }
  }
}
</style>