<template>
  <div class="education-wrap">
    <div class="banner">
      <h2>建模助手BIM学堂</h2>
      <p>面向BIM生态用户的一站式学习成长平台</p>
    </div>
    <div class="learning-wrap" id="video">
      <h4>功能学习（必修课）</h4>
      <p>每个视频攻破一个实用知识点，每天进步一点点</p>
      <div class="btn">
        <mt-button type="primary" @click="toLink('video')">视频教程</mt-button>
        <mt-button type="default" @click="toLink('doc')">文档教程</mt-button>
      </div>
    </div>
    <div class="elective-wrap">
      <h4>项目视频（选修课）</h4>
      <p>科学、完善、有效地解析项目案例，直击你的专业瓶颈</p>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div class="videoBox">
            <video-item
              :item="item"
              pageName="建模进修"
              :showOfficial="false"
            ></video-item>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <a href="/case" class="look-all">查看全部>></a>
    </div>
    <ul class="plate-list">
      <li class="plate-item" v-for="(item, index) in bottomList" :key="index">
        <a :href="item.path" class="plate-item-url">
          <img :src="item.icon" alt="" />
          <span>{{ item.num + item.des }}</span>
          <img
            class="right"
            src="https://fs.zhbim.com/imgs/mobile/home/down.png"
            alt=""
          />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import videoItem from "@/components/videoItem";
import { Toast } from "mint-ui";
export default {
  name: "education",
  components: {
    videoItem,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 6,
        freeMode: true,
        loop: false,
        resistanceRatio: 0,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
      list: [
        {
          path: "https://fs.zhbim.com/imgs/webPC/video/%E5%B9%BF%E5%B7%9E%E5%8C%97%E7%AB%99.mp4",
        },
        {
          path: "https://fs.zhbim.com/imgs/webPC/video/%E4%B9%9D%E9%BE%99%E6%96%B0%E5%9F%8E.mp4",
        },
        {
          path: "https://fs.zhbim.com/imgs/webPC/video/%E8%90%9D%E5%B2%97%E4%B8%AD%E5%BF%83%E5%9F%8E%E5%8C%BA.mp4",
        },
        {
          path: "https://fs.zhbim.com/imgs/webPC/video/%E5%AE%9E%E9%AA%8C%E4%B8%AD%E5%AD%A6.mp4",
        },
      ],
      bottomList: [
        {
          icon: "https://fs.zhbim.com/imgs/webPC/study/module_icon.png",
          num: "7大",
          des: "板块BIM",
          path: "/productCenter",
        },
        {
          icon: "https://fs.zhbim.com/imgs/webPC/study/project_icon.png",
          num: "16项",
          des: "获奖项目视频",
          path: "/case",
        },
        {
          icon: "https://fs.zhbim.com/imgs/webPC/study/recorded_icon.png",
          num: "82个",
          des: "自学录播课",
          path: "/study",
        },
        {
          icon: "https://fs.zhbim.com/imgs/webPC/study/special_column_icon.png",
          num: "100+篇",
          des: "专栏文章",
          path: "/informationCenter",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.boxId) {
      this.$nextTick(() => {
        document.getElementById("video").scrollIntoView(true);
        window.scrollBy(0, -120);
      });
    }
  },
  methods: {
    toLink(type) {
      if (type == "video") {
        this.$router.push({ path: "/study" });
      } else {
        Toast(
          "正在快马加鞭输出教程，请耐心等候~（着急的话上PC端官网就可以看啦）"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.education-wrap {
  .banner {
    background-image: url("https://fs.zhbim.com/imgs/mobile/educationBanner.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 6.3rem;
    text-align: center;
    h2 {
      font-size: 0.42rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.4rem;
      padding-top: 0.4rem;
      padding-bottom: 0.36rem;
    }
    p {
      margin: 0 auto;
      font-size: 0.24rem;
      color: #666666;
      line-height: 0.36rem;
    }
  }
  .learning-wrap {
    text-align: center;
    padding-top: 0.48rem;
    h4 {
      font-size: 0.36rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.6rem;
    }
    p {
      font-size: 0.24rem;
      font-weight: 500;
      line-height: 0.48rem;
      color: #666666;
      margin-bottom: 0.48rem;
    }
    .btn {
      margin-bottom: 0.6rem;
      .mint-button {
        background-color: #f6f9fe;
        border-radius: 0;
        width: 2.1rem;
        height: 0.64rem;
        line-height: 0.62rem;
        font-size: 0.24rem;
        color: #4a86ff;
        border: 1px solid #bdcef0;
        margin: 0 0.28rem;
      }
      .mint-button--primary {
        background-color: #4a86ff;
        border-radius: 0;
        width: 2.1rem;
        height: 0.64rem;
        line-height: 0.62rem;
        font-size: 0.24rem;
        border: 1px solid #4a86ff;
        color: #fff;
      }
    }
  }
  .elective-wrap {
    text-align: center;
    background-color: #1c2437;
    padding-top: 0.52rem;
    h4 {
      font-size: 0.36rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.6rem;
    }
    p {
      font-size: 0.24rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.48rem;
      margin-bottom: 0.32rem;
    }
    .swiper {
      .swiper-slide {
        width: 3.28rem !important;
        height: 1.86rem;
        background: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(23, 29, 45, 0.2);
        border-radius: 4px;
        transform: translate3d(0.3rem, 0px, 0px);
        padding: 0.1rem;
        .videoBox {
          width: 100%;
          height: 100%;
          overflow: hidden;
          ::v-deep .video-js .vjs-progress-control {
            min-width: 3em;
          }
          ::v-deep .video-js .vjs-control {
            width: 3em;
          }
        }
      }
    }
    .look-all {
      color: #4a86ff;
      line-height: 1.1rem;
      margin-bottom: 0;
    }
  }
  .plate-list {
    padding: 0.5rem 0.3rem;
    .plate-item {
      padding: 0.4rem 0.35rem 0.3rem 0.4rem;
      margin-bottom: 0.2rem;
      background: #f3f7ff;
      box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      .plate-item-url {
        display: block;
        width: 100%;
      }
      img {
        width: 0.5rem;
        vertical-align: middle;
      }
      span {
        font-size: 0.3rem;
        margin-left: 0.26rem;
        vertical-align: middle;
      }
      .right {
        float: right;
        margin-top: 0.1rem;
        transform: rotate(-90deg);
        height: 0.18rem;
        width: 0.32rem;
      }
    }
  }
}
</style>