//cookies处理

import Cookies from 'js-cookie'

const TokenKey = process.env.VUE_APP_COOKIE_NAME //记录token
const TokenRefshKey = 'TokenRefshKey' //记录刷新token
const UserInfoKey = 'UserInfoKey' //记录用户基础信息
const moduleListKey = 'ModuleListKey' //记录产品列表信息
export function getToken() { //获取token
  return Cookies.get(TokenKey)
}

export function setToken(token) { //设置token
  return Cookies.set(TokenKey, token)
}

export function removeToken() { //移除token
  return Cookies.remove(TokenKey)
}

export function getRefshToken() { //获取刷新token
  return Cookies.get(TokenRefshKey)
}

export function setRefshToken(token) { //设置刷新token
  return Cookies.set(TokenRefshKey, token)
}

export function removeRefshToken() { //移除刷新token
  return Cookies.remove(TokenRefshKey)
}

export function getUser() { //获取用户信息
  return JSON.parse(localStorage.getItem(UserInfoKey))
}

export function setUser(userInfo) { //设置用户信息
  return localStorage.setItem(UserInfoKey, JSON.stringify(userInfo))
}

export function removeUser() { //移除用户信息
  return localStorage.removeItem(UserInfoKey)
}

export function getModuleList() { //获取模块列表信息
  return JSON.parse(localStorage.getItem(moduleListKey))
}

export function setModuleList(moduleList) { //设置模块列表信息
  return localStorage.setItem(moduleListKey, JSON.stringify(moduleList))
}

export function removeModuleList() { //移除模块列表信息
  return localStorage.removeItem(moduleListKey)
}
