<template>
  <div class="study-wrap">
    <div class="title-wrap">
      <mt-field placeholder="请输入您想搜索的内容" @blur.native.capture="onBlur(keyword)" v-model="keyword">
        <img @click="search" class="search-icon" src="https://fs.zhbim.com/imgs/mobile/customer/search-icon.png" height="45px"
          width="100px">
      </mt-field>
    </div>
    <div class="main-wrap">
      <mt-navbar v-model="selected">
        <mt-tab-item id="Video">视频教程</mt-tab-item>
        <!-- <mt-tab-item id="Document">文档教程</mt-tab-item> -->
      </mt-navbar>
      <mt-tab-container v-model="selected">
        <div class="select-wrap">
          <span @click="modelShow=!modelShow">{{moduleName}}
            <van-icon name="arrow-down" />
          </span>
          <ul class="model-list" v-show="modelShow">
            <li class="model-item" @click="changeModel(item.key)" :class="{active:Category==item.key}"
              v-for="item,index in moduleList" :key="index">
              {{item.value}}{{item.key!='ZH.Plugin.YQBY'?'模块':''}}</li>
          </ul>
        </div>
        <mt-tab-container-item id="Video">
          <ul class="video-list">
            <li class="video-item" v-for="item,index in videoList" :key="index">
              <div class="videoBox">
                <video-item :item="item" pageName="建模进修" :showOfficial="true"></video-item>
              </div>
            </li>
          </ul>
          <div class="noData" v-if="videoList.length==0">
            <div class="imgBox">
              <img src="@/assets/img/noData.png" alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手" title="暂无数据" />
            </div>
            <p class="p1">搜索无结果，换个词试试吧</p>
          </div>
          <div v-if="page.total>videoList.length" @click="lookMore" class="look-more">展开查看更多<img
              src="https://fs.zhbim.com/imgs/mobile/product/down.png">
          </div>
          <div v-if="page.total===videoList.length&&videoList.length>0" class="no-more">暂无更多教程</div>
        </mt-tab-container-item>
        <mt-tab-container-item id="Document">
          <ul class="doc-list">
            <li class="doc-item" v-for="item,index in fileList" @click="toDetail(item)" :key="index">
              <img class="fileImage" src="@/assets/img/document.jpg" :alt="item.title" :title="item.title" />
              <div class="text">
                <h4 style="font-weight: 500">
                  【{{item.categories == "ZH.Plugin.General"
                        ? "综合"
                        : item.categories == "ZH.Plugin.Draw"
                        ? "出图"
                        : item.categories == "ZH.Plugin.MEP"
                        ? "机电"
                        : item.categories == "ZH.Plugin.AEC"?"土建":'有求必应'
                    }}】{{ item.title }}
                </h4>
              </div>
            </li>
          </ul>
          <div class="noData" v-if="fileList.length==0">
            <div class="imgBox">
              <img src="@/assets/img/noData.png" alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手" title="暂无数据" />
            </div>
            <p class="p1">搜索无结果，换个词试试吧</p>
          </div>
          <div v-if="page.total>fileList.length" @click="lookMore" class="look-more">展开查看更多<img
              src="https://fs.zhbim.com/imgs/mobile/product/down.png">
          </div>
          <div v-if="page.total===fileList.length&&fileList.length>0" class="no-more">暂无更多教程</div>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>
  </div>
</template>

<script>
import videoItem from '@/components/videoItem'
import { resourceClassify, getResourcesList } from '@/api/resource'
export default {
  components: {
    videoItem,
  },
  data() {
    return {
      modelShow: false,
      selected: 'Video', //文档and视频切换
      keyword: '', //关键字搜索
      moduleList: [], //模块内容
      videoList: [], //视频容器
      fileList: [], //文档容器
      Category: '', //模块切换
      page: {
        total: 0, //总数
        current: 1, //当前页
        size: 6, //每页个数
      },
    }
  },
  created() {
    if (this.$route.query.moduleId) {
      this.Category = this.$route.query.moduleId
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword
    }
    this.getModule()
  },
  mounted() {
    this.getList();
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  computed: {
    moduleName() {
      for (let i = 0; i < this.moduleList.length; i++) {
        const element = this.moduleList[i];
        console.log(element);
        if (this.Category === element.key) {
          let name = ''
          return name = this.Category != 'ZH.Plugin.YQBY' ? element.value + '模块' : element.value
        }
      }
    }
  },
  watch: {
    selected() {
      this.videoList = []
      this.fileList = []
      this.getList()
    },
    keyword(newKey, oldKey) {
      console.log(22222222);
      if (!newKey) {
        setTimeout(() => {
          if (!this.keyword) {
            this.search()
          }
        }, 500);
      }
    },
  },
  methods: {
    getModule() {
      //获取模块列表
      resourceClassify().then((res) => {
        this.moduleList = res
        this.moduleList.unshift({ key: '', value: '全部' })
      })
    },
    getList() {
      //获取页面数据
      getResourcesList({
        Text: this.keyword,
        Type: this.selected,
        Category: this.Category,
        PageIndex: this.page.current,
        PageSize: this.page.size,
      }).then((res) => {
        if (this.selected == 'Video') {
          this.videoList = this.videoList.concat(res.items)
        }
        if (this.selected == 'Document') {
          this.fileList = this.fileList.concat(res.items)
        }
        this.page.total = res.totalCount
      })
    },
    search() {
      this.videoList = []
      this.fileList = []
      this.page.current = 1
      this.getList()
    },
    changeModel(key) {
      this.Category = key
      this.modelShow = false
      this.search()
    },
    toDetail(item) {
      this.$router.push({
        path: `/documentDetail/${item.id}`,
      })
    },
    lookMore() {
      this.page.current++;
      this.getList()
    },
    handleScroll() {
      if (this.modelShow) {
        this.modelShow = false
      }
    },
  }
}
</script>
<style lang='scss' scoped>
.study-wrap {
  padding-top: 0.42rem;
  .title-wrap {
    padding: 0 0.3rem;
    .mint-field {
      height: 0.8rem;
      background: #f4f5f9;
      border: 1px solid #ebebeb;
      border-radius: 0.4rem;
      ::v-deep .mint-field-core {
        background-color: #f4f5f9;
        line-height: 0.5rem;
      }
      ::v-deep .mint-cell-wrapper {
        padding: 0 0.34rem;
        font-size: 0.24rem;
      }
      ::v-deep .mint-field-clear {
        margin-right: 0.2rem;
      }
      .search-icon {
        width: 0.36rem;
        height: 0.36rem;
      }
    }
  }
  .main-wrap {
    padding-top: 0.26rem;
    ::v-deep .mint-navbar {
      .mint-tab-item-label {
        font-size: 0.3rem;
      }
      .mint-tab-item {
        padding: 0.16rem 0;
        margin: 0 0.8rem;
      }
      .is-selected {
        border-bottom-color: #fff;
        color: #4a86ff;
      }
    }
    ::v-deep .mint-tab-container {
      padding: 0.4rem 0.3rem;
      background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
      .mint-tab-container-wrap {
        flex-direction: column;
        .select-wrap {
          width: 100%;
          height: 0.6rem;
          position: relative;
          z-index: 9;
          span {
            z-index: 9;
            font-size: 0.24rem;
            color: #333;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding-left: 0.4rem;
            padding-right: 0.18rem;
            line-height: 0.6rem;
            background: #ffffff;
            box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
            border-radius: 10px;
            .van-icon {
              margin-left: 0.1rem;
            }
          }
          .model-list {
            position: absolute;
            top: 0rem;
            right: 0;
            padding: 0.2rem 0.53rem;
            padding-top: 0.8rem;
            background: #f8f9fe;
            box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
            border-radius: 10px;
            .model-item {
              font-size: 0.24rem;
              line-height: 0.24rem;
              margin-bottom: 0.36rem;
              color: #666666;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .active {
              color: #333;
            }
          }
        }
        .video-list {
          margin-top: 0.3rem;
          // min-height: 3.5rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .video-item {
            width: 48%;
            height: 2.5rem;
            margin-bottom: 0.3rem;
            .videoBox {
              width: 100%;
              height: 100%;
              overflow: hidden;
            }
          }
        }
        .doc-list {
          margin-top: 0.3rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .doc-item {
            width: 48%;
            height: 100%;
            margin-bottom: 0.3rem;
            .fileImage {
              width: 100%;
            }
          }
        }
        .look-more {
          text-align: center;
          font-size: 0.28rem;
          font-weight: 500;
          color: #4a86ff;
          img {
            vertical-align: middle;
            width: 0.22rem;
            height: auto;
            margin-left: 0.1rem;
          }
        }
        .no-more {
          text-align: center;
          font-size: 0.28rem;
          font-weight: 500;
          color: #999;
          padding: 0.5rem 0;
        }
        .noData {
          .imgBox {
            img {
              width: 100%;
            }
          }
          .p1 {
            text-align: center;
            color: #999;
          }
        }
      }
    }
  }
}
</style>