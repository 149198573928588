<template>
  <div class="case-detail">
    <div class="brief-wrap">
      <img :src="detail.img" :alt="detail.title" :title="detail.title">
      <img class="icon" src="https://fs.zhbim.com/imgs/webPC/case/banner-icon.png" alt="" title="" />
      <div class="textBox">
        <h4 style="margin-top:0px;">{{ detail.title }}</h4>
        <p>{{ detail.description }}</p>
      </div>
    </div>
    <div id="overview" class="overview-wrap item-wrap">
      <h4>项目概况</h4>
      <div class="line"></div>
      <p>
        {{ detail.survey }}
      </p>
      <img v-if="detailImg" class="detail-img" :src="detail.detailImg" alt="项目概况" title="项目概况" />
      <div class="btn-wrap">
        <span v-if="!detailImg" @click="detailImg=true" class="look-detail">展开查看更多<img
            src="https://fs.zhbim.com/imgs/mobile/product/down.png"></span>
        <span v-else @click="packUp" class="look-detail">收起<img src="https://fs.zhbim.com/imgs/mobile/product/down.png"
            class="up-icon"></span>
      </div>
    </div>
    <div class="modules-wrap item-wrap">
      <h4>相关模块</h4>
      <div class="line"></div>
      <a class="modules-list" v-for="(item, index) in detail.relevantModulars"
        :href="`/productCenter?id=${item.productCode}&boxId=module`" :key="index">
        <img class="icon" :src="
                item.productCode == 'ZH.Plugin.General'
                  ? 'https://fs.zhbim.com/imgs/webPC/home/synthesize.png'
                  : item.productCode == 'ZH.Plugin.AEC'
                  ? 'https://fs.zhbim.com/imgs/webPC/home/civil_engineering.png'
                  : item.productCode == 'ZH.Plugin.MEP'
                  ? 'https://fs.zhbim.com/imgs/webPC/home/electromechanical.png'
                  : item.productCode == 'ZH.Plugin.Draw'
                  ? 'https://fs.zhbim.com/imgs/webPC/home/plot.png'
                  : 'https://fs.zhbim.com/imgs/yqby.png'
              " alt="" title="" />
        <div class="text">
          <h5>{{ item.modularName }}模块</h5>
          <p>{{ item.modularDescription }}</p>
        </div>
      </a>
    </div>
    <div class="function-wrap item-wrap">
      <h4>相关功能</h4>
      <div class="line"></div>
      <ul class="list">
        <template v-for="(item, index) in detail.functions">
          <li class="item" v-if="index<showNum" :key="index">
            <a :href="`/productCenter?id=${item.productCode}&boxId=module`">
              <img class="label" :src="item.img" :alt="item.name" :title="item.name" />
              <div class="text">
                <h5>{{ item.name }}</h5>
                <p>{{ item.description }}</p>
              </div>
            </a>
          </li>
        </template>
      </ul>
      <div v-if="detail.functions.length>showNum" @click="showNum+=4" class="look-more">展开查看更多<img
          src="https://fs.zhbim.com/imgs/mobile/product/down.png">
      </div>
    </div>
  </div>
</template>
<script>
import { getCaseDetail } from '@/api/resource'
export default {
  data() {
    return {
      showNum: 4,
      detailImg: false,
      key: '', //获取key值
      detail: '', //获取详情数据
    }
  },
  mounted() {
    this.key = this.$route.params.id
    this.getDetail()
  },
  methods: {
    getDetail() {
      getCaseDetail({
        id: this.key,
        terminal: 1
      }).then((res) => {
        this.detail = res
      })
    },
    packUp() {
      this.detailImg = false
      document.querySelector('#overview').scrollIntoView(true)
      window.scrollBy(0, -120)
    }
  }
}
</script>
<style lang="scss" scoped>
.case-detail {
  padding: 0.3rem;
  .brief-wrap {
    position: relative;
    background-color: #1c2437;
    border-radius: 8px;
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
    }
    .icon {
      width: 1.2rem;
      position: absolute;
      right: 0.24rem;
      bottom: 0.18rem;
    }
    .textBox {
      color: #fff;
      padding: 0.3rem;
      height: 3.64rem;
      h4 {
        text-align: center;
        font-size: 0.3rem;
        font-weight: bold;
        line-height: 0.74rem;
        margin-bottom: 0.3rem;
      }
      p {
        line-height: 0.42rem;
        font-size: 0.24rem;
        color: #ececec;
      }
    }
  }
  .item-wrap {
    h4 {
      font-size: 0.36rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.82rem;
      margin-top: 0.36rem;
    }
    .line {
      width: 0.4rem;
      height: 3px;
      background: #4a86ff;
      border-radius: 3px;
      margin-bottom: 0.16rem;
    }
  }
  .overview-wrap {
    p {
      font-size: 0.24rem;
      font-weight: 500;
      color: #666666;
      line-height: 0.42rem;
    }
    .detail-img {
      width: 100%;
      margin-top: 0.2rem;
    }
    .btn-wrap {
      text-align: center;
      color: #4a86ff;
      font-size: 0.24rem;
      line-height: 0.24rem;
      .look-detail {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 0.2rem;
        margin-left: 0.16rem;
      }
      .up-icon {
        transform: rotate(180deg);
      }
    }
  }
  .modules-wrap {
    .line {
      margin-bottom: 0.46rem;
    }
    .modules-list {
      display: flex;
      align-items: center;
      margin-bottom: 0.54rem;
      .icon {
        width: 0.85rem;
        margin-right: 0.36rem;
      }
      .text {
        h5 {
          font-size: 0.24rem;
          font-weight: 500;
          color: #333333;
          margin-bottom: 0.16rem;
        }
        p {
          font-size: 0.2rem;
          font-weight: 500;
          color: #666666;
        }
      }
    }
  }
  .function-wrap {
    .line {
      margin-bottom: 0.23rem;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        display: flex;
        margin-bottom: 0.32rem;
        img {
          flex-shrink: 0;
          width: 0.42rem;
          height: 0.42rem;
          margin-right: 0.12rem;
        }
        .text {
          h5 {
            font-size: 0.24rem;
            font-weight: 500;
            color: #333333;
            margin-bottom: 0.16rem;
          }
          p {
            font-size: 0.2rem;
            font-weight: 500;
            color: #999;
            width: 2.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .look-more {
      text-align: center;
      font-size: 0.28rem;
      font-weight: 500;
      color: #4a86ff;
      padding-bottom: 0.1rem;
      img {
        vertical-align: middle;
        width: 0.22rem;
        height: auto;
        margin-left: 0.1rem;
      }
    }
  }
}
</style>