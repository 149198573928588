<template>
  <div>
    <ul class="returnTop" v-show="returnTopShow">
      <li class="item item1" @click="goTop(1)">
        <img src="https://fs.zhbim.com/imgs/mobile/user.png" class="img" />
      </li>
      <li class="item" @click="goTop(2)">
        <img src="https://fs.zhbim.com/imgs/mobile/returnTop.png" class="img" />
      </li>
    </ul>
    <van-overlay :show="show">
      <div class="erweima">
        <img src="https://fs.zhbim.com/imgs/erweima.jpg" class="img" />
        <p class="text">扫码关注公众号</p>
      </div>
      <div class="pcClose" @click="show= false"><van-icon name="cross"></van-icon></div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      returnTopShow: false,
      scrollTop: "",
    };
  },
  watch: {
    handleScroll(e) {
      const top = e.target.scrollTop;
      if (top > 100) {
        this.returnTopShow = true;
      } else {
        this.returnTopShow = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.returnTopShow = true;
      } else {
        this.returnTopShow = false;
      }
    },
    goTop(type) {
      if (type == 1) {
        this.show = true;
      }
      if (type == 2) {
        let timer = null,
          _that = this;
        cancelAnimationFrame(timer);
        timer = requestAnimationFrame(function fn() {
          if (_that.scrollTop > 0) {
            _that.scrollTop -= 50;
            document.body.scrollTop = document.documentElement.scrollTop =
              _that.scrollTop;
            timer = requestAnimationFrame(fn);
          } else {
            cancelAnimationFrame(timer);
            _that.returnTopShow = false;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 760px) {
  .returnTop {
    right: calc(50% - 4.8rem) !important;
  }
}

.returnTop {
  background-color: #ffffff;
  position: fixed;
  right: calc(50% - 3.6rem);
  bottom: 1rem;
  border: 1px solid #999999;
  overflow: hidden;
  z-index: 100000;

  .item {
    text-align: center;
    padding: 0.2rem 0.2rem;

    .img {
      width: 0.48rem;
      height: 0.48rem;
      display: inline-block;
    }
  }

  .item1 {
    background-color: #1f4af2;
  }
}
.pcClose{
    position: fixed;
    top: 83%;
    left: 50%;
    width: 0.6rem;
    height: 0.6rem;
    text-align: center;
    line-height: 0.6rem;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
    font-size: 0.32rem;
    font-weight: 500;
    transform: translate(-50%,-50%);
}
.erweima {
  width: 6rem;
  background: #ffffff;
  border-radius: 0.1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000000;
  text-align: center;
  padding: 0.2rem 0px 0.4rem;
  .img{
    width: 5rem;
    height: auto;
    margin: 0px auto;
  }
  .text{
    font-size: 0.3rem;
    color: #333;
  }
}
</style>
