//关于案例模块的接口
import request from '@/utils/request'


//获取经典案例列表/常见问题列表
export function getClassicCases(params) {
  return request({
    url: 'resource/meta/search',
    method: 'get',
    params:params
  })
}

//获取单个经典案例列表
export function getClassicCasesSingle(params) {
  return request({
    url: 'resource/meta',
    method: 'get',
    params:params
  })
}

//获取BIM咨询中心列表
export function getBimInformationCenterList() {
  return request({
    url: 'resource/meta/search',
    method: 'get',
    params:{Type:'BimInformationCenter'}
  })
}

//获取用户反馈类型列表
export function getFeedbackTypeList() {
  return request({
    url: 'resource/meta/search',
    method: 'get',
    params:{Type:'FeedbackType'}
  })
}

//用户反馈
export function feedback(data) {
  return request({
    url: 'user/feedback',
    method: 'post',
    data
  })
}

//获取教程分类信息
export function resourceClassify(params) {
  return request({
    url: 'resource/TutorialCategory',
    method: 'get',
    params:params
  })
}

//获取视频教程列表
export function getResourcesList(params) {
  return request({
    url: 'resource/tutorial/search',
    method: 'get',
    params:params
  })
}

//获取单个视频教程
export function getResourcesSingle(params) {
  return request({
    url: 'resource/tutorial',
    method: 'get',
    params:params
  })
}

//搜索教程
export function searchTutorials(params) {
  return request({
    url: 'resource/tutorial/search',
    method: 'get',
    params
  })
}

//添加元数据
export function resourceMetaAll(data) {
  return request({
    url: 'resource/meta',
    method: 'POST',
    data
  })
}

//获取单个元数据
export function getResourceMetaAll(params) {
  return request({
    url: 'resource/meta',
    method: 'get',
    params
  })
}

//记录模块点击日志
export function modulePoint(data) {
  return request({
    url: 'resource/sys/logpage',
    method: 'POST',
    data
  })
}

//记录教程点击日志
export function classPoint(data) {
  return request({
    url: 'resource/sys/logtutorial',
    method: 'POST',
    data
  })
}

//记录链接点击日志
export function loglinkPoint(data) {
  return request({
    url: 'resource/sys/loglink',
    method: 'POST',
    data
  })
}

//添加登录日志
export function loginPoint(data) {
  return request({
    url: 'user/my/addLog',
    method: 'POST',
    data
  })
}

//获取首页教程信息
export function tutorialTop(params) {
  return request({
    url: 'resource/tutorial/top',
    method: 'get',
    params
  })
}

//上传图片
export function uploadImg(data) {
  return request({
    url: 'media/upload/img',
    method: 'POST',
    data
  })
}

//获取各类教程信息
export function resourceAllGet(params) {
  return request({
    url: 'resource/tutorial/count/get',
    method: 'get',
    params
  })
}

//获取经典案例列表
export function getCaseList(params) {
  return request({
    url: 'config/case/search',
    method: 'get',
    params
  })
}
//获取经典案例详情
export function getCaseDetail(params) {
  return request({
    url: 'config/case/get',
    method: 'get',
    params
  })
}
