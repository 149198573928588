<template>
  <div class="home-consult">
    <p class="title">服务万千BIMer的平台</p>
    <p class="text">面向广大BIM从业人员的一站式学习成长平台</p>
    <div class="list">
      <div class="item" v-for="(item, index) in consultList" :key="index">
        <img
          src="https://fs.zhbim.com/imgs/mobile/home/video_course.png"
          class="bg"
          v-if="item.type == 'studyvid'"
        />
        <img
          src="https://fs.zhbim.com/imgs/mobile/home/document_course.png"
          class="bg"
          v-else
        />
        <p class="name">{{ item.title }}</p>
        <p class="des">{{ item.des }}</p>
        <div class="childList">
          <div
            class="childItem"
            v-for="(child, childIndex) in item.module"
            :key="childIndex"
            @click="linkTo(item, child)"
          >
            {{ child.title }}
          </div>
          <div class="childItem1" v-if="(item.module.length%3!=0)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
export default {
  data() {
    return {
      consultList: [
        {
          type: "studyvid",
          title: "视频教程",
          des: "详细操作指导",
          module: [
            {
              type: "",
              title: "全部模块",
            },
            {
              type: "ZH.Plugin.General",
              title: "综合模块",
            },
            {
              type: "ZH.Plugin.AEC",
              title: "土建模块",
            },
            {
              type: "ZH.Plugin.MEP",
              title: "机电模块",
            },
            {
              type: "ZH.Plugin.Draw",
              title: "出图模块",
            },
            {
              type: "ZH.Plugin.YQBY",
              title: "有求必应",
            },
            {
              type: "ZH.Plugin.construction",
              title: "施工模块",
            },
            {
              type: "ZH.Plugin.Family",
              title: "族库模块",
            },
          ],
          imgBg: "https://fs.zhbim.com/imgs/webPC/home/video_course.png",
        },
        {
          type: "studydoc",
          title: "文档教程",
          des: "文档快速入门",
          module: [
            {
              type: "",
              title: "全部模块",
            },
            {
              type: "ZH.Plugin.General",
              title: "综合模块",
            },
            {
              type: "ZH.Plugin.AEC",
              title: "土建模块",
            },
            {
              type: "ZH.Plugin.MEP",
              title: "机电模块",
            },
            {
              type: "ZH.Plugin.Draw",
              title: "出图模块",
            },
            {
              type: "ZH.Plugin.YQBY",
              title: "有求必应",
            },
            {
              type: "ZH.Plugin.construction",
              title: "施工模块",
            },
            {
              type: "ZH.Plugin.Family",
              title: "族库模块",
            },
          ],
          imgBg: "https://fs.zhbim.com/imgs/webPC/home/document_course.png",
        },
      ],
    };
  },
  methods: {
    linkTo(item, child) {
      if (item.type == "studydoc") {
        Toast({
          message:
            "正在快马加鞭输出教程，请耐心等候~（着急的话上PC端官网就可以看啦）",
          position: "middle",
          duration: 3500,
        });
      } else {
        this.$router.push({
          path: `/study?moduleId=`+child.type,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home-consult {
  text-align: center;
  padding-top: 0.6rem;
  background: #f4f5f9;
  padding-bottom: 0.4rem;
  .title {
    font-size: 0.36rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.2rem;
  }
  .text {
    font-size: 0.24rem;
    font-weight: 500;
    color: #666;
  }
  .list {
    padding: 0px 0.3rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-top: 0.43rem;
    .item {
      background: #fff;
      position: relative;
      margin-bottom: 0.3rem;
      padding: 0.72rem 0.35rem 0.3rem;
      text-align: left;
      .bg {
        width: 2.3rem;
        height: auto;
        position: absolute;
        top: 0.14rem;
        right: 0.17rem;
      }
      .name {
        font-size: 0.3rem;
        color: #333;
      }
      .des {
        font-size: 0.2rem;
        color: #999;
        margin-top: 0.1rem;
        margin-bottom: 0.52rem;
      }
      .childList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .childItem {
          width: 1.8rem;
          height: 0.6rem;
          text-align: center;
          line-height: 0.6rem;
          border: 1px solid #8c95b0;
          margin-bottom: 0.16rem;
          font-size: 0.24rem;
          color: #8c95b0;
        }
        .childItem1{
          width: 1.8rem;
        }
      }
    }
  }
}
</style>
