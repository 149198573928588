<template>
  <div class="videoItem">
    <video-player
      class="item"
      :class="isPaused ? 'videoPaused' : ''"
      ref="videoPlayer"
      :events="events"
      :playsinline="playsinline"
      @play="onPlayerPlay($event)"
      @ended="end($event)"
      @fullscreenchange="onFullscreenChange($event)"
      @click="fullScreen"
      :options="playerOptions[0]"
      v-if="playerOptions[0]"
    >
    </video-player>
    <div class="text" v-if="showOfficial">
      <h4 style="font-weight: 500">
        【{{
          item.categories == "ZH.Plugin.General"
            ? "综合"
            : item.categories == "ZH.Plugin.Draw"
            ? "出图"
            : item.categories == "ZH.Plugin.MEP"
            ? "机电"
            : item.categories == "ZH.Plugin.AEC"
            ? "土建"
            : "有求必应"
        }}】{{ item.title }}
      </h4>
      <!-- <span>{{ item.description }}</span> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showOfficial: {
      type: Boolean,
      default: true,
    },
    pageName: {
      type: String,
      default: "",
    },
    plate: {
      type: String,
      default: "",
    },
    context: {
      type: String,
      default: "",
    },
  },
  watch: {
    item(val, old) {
      this.playerOptions = [
        {
          playbackRates: [1.0, 2.0, 3.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: true, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/MP4",
              // type: 'video/ogg',
              src: this.item.path, //url地址
            },
          ],
          poster: "", //封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        },
      ];
    },
  },
  data() {
    return {
      playerOptions: [], //视频播放
      events: ["fullscreenchange"],
      isPaused: true,
    };
  },
  computed: {
    playsinline() {
      let ua = navigator.userAgent.toLocaleLowerCase();
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      } else {
        // ios端
        return true;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.playerOptions = [
        {
          playbackRates: [1.0, 2.0, 3.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4",
              src: this.item.path, //url地址
            },
          ],
          poster: "", //封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        },
      ];
    });
  },
  methods: {
    fullScreen() {
      const player = this.$refs.videoPlayer.player;
      player.requestFullscreen(); //调用全屏api方法
      player.isFullscreen(true);
      player.play();
    },
    onFullscreenChange(val) {
      const player = this.$refs.videoPlayer.player;
      if (val.isFullscreen_) {
        player.play();
        this.isPaused = false;
      } else {
        player.pause();
        this.isPaused = true;
      }
    },
    full(element) {
      //做兼容处理
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        var videobox = document.getElementsByClassName("video-player");
        var cssText = "width:100%;height:100%;overflow:hidden;";
        videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
        videobox.IsFullScreen = true;
      }
    },
    onPlayerPlay(player) {
      this.full(player);
      if (this.item.categories) {
        this.item.categories == "ZH.Plugin.General"
          ? "[综合]"
          : this.item.categories == "ZH.Plugin.Draw"
          ? "[出图]"
          : this.item.categories == "ZH.Plugin.MEP"
          ? "[机电]"
          : "[土建]" + this.item.title;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";
.videoItem {
  width: 100%;
  height: 100%;
  .item {
    // height: 1.65rem;
  }
  .text {
    @include text($text, $titleColor);
    margin-top: 0.1rem;
    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<style lang="scss">
@import "@/static/variables.scss";
.videoItem {
  .videoPaused {
    .vjs-control-bar {
       display: none;
    }
  }
  .video-js {
    .vjs-big-play-button {
      width: 33px;
      height: 33px;
      line-height: 33px;
      @include abCenter();
      text-align: center;
      border: 2px solid #ffffff;
      background: transparent;
      border: none;
      border-radius: 50%;
      -webkit-border-radius: 50%;
    }
    &.vjs-paused {
      .vjs-poster {
        display: block !important;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .vjs-poster {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
  }
}
</style>
