<template>
  <div class="home-information">
    <div class="top">
      <div class="title">
        <span>资讯中心</span
        ><img
          src="https://fs.zhbim.com/imgs/mobile/home/case_dip.png"
          class="dip"
        />
      </div>
      <p class="smallTitle">Information Center</p>
    </div>
    <div class="info">
      <div class="title">最新资讯</div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in articleList"
          :key="index"
          @click="linkTo(item.linkUrl)"
        >
          <img :src="item.img" class="img" />
          <div class="textBox">
            <p class="name">{{ item.title }}</p>
            <p class="description">{{ item.description }}</p>
            <p class="source">
              <span class="sp1">来源：{{ item.source }}</span>
              <span class="sp2">{{ item.publishTime }}</span>
            </p>
          </div>
        </div>
      </div>
      <a href="/informationCenter" class="link">更多资讯<img src="https://fs.zhbim.com/imgs/mobile/home/next.png"/></a>
    </div>
  </div>
</template>
<script>
import { productArticle } from "@/api/home";
export default {
  data() {
    return {
      articleList: [],
    };
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    getArticle() { //获取列表数据
      productArticle().then((res) => {
        this.articleList = res;
      });
    },
    linkTo(URL){ //页面跳转
        window.open(URL)
    }
  },
};
</script>
<style lang="scss" scoped>
.home-information {
  padding: 0.6rem 0.3rem 0.43rem;
  .top {
    .title {
      span,
      .dip {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        font-size: 0.36rem;
        font-weight: 500;
        color: #4a86ff;
        font-family: PingFang SC;
      }
      .dip {
        width: 0.24rem;
        height: auto;
        margin-right: 0.08rem;
      }
    }
    .smallTitle {
      font-size: 0.3rem;
      color: #4a86ff;
      font-weight: 500;
      opacity: 0.2;
      font-family: PingFang SC;
      margin-top: -0.2rem;
    }
  }
  .info {
    margin-top: 0.3rem;
    .title {
      font-size: 0.3rem;
      font-size: 500;
      color: #333;
      border-left: 0.06rem solid #4a86ff;
      padding: 0px 0.06rem;
      line-height: 1;
    }
    .list {
      margin-top: 0.05rem;
      .item {
        border: 0.02rem solid #ffffff;
        background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
        box-shadow: 0.05rem 0.03rem 0.06rem 0px rgba(232, 235, 247, 0.86);
        border-radius: 0.04rem;
        margin-top: 0.2rem;
        padding: 0.2rem 0.16rem;
        display: flex;
        align-items: center;
        justify-content: left;
        .img {
          width: 2rem;
          height: 1.3rem;
          margin-right: 0.23rem;
        }
        .textBox {
          .name {
            font-size: 0.26rem;
            color: #333;
            font-weight: bold;
          }
          .description {
            font-size: 0.2rem;
            color: #999;
            font-weight: 500;
            margin: 0.1rem 0px 0.2rem;
          }
          .source {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.18rem;
            font-weight: 500;
            color: #999;
            .sp1 {
              color: #4a86ff;
            }
          }
        }
      }
    }
    .link{
      width: 100%;
      display: block;
      text-align: center;
      font-size: 0.24rem;
      color: #4A86FF;
      font-weight: 500;
      margin-top: 0.3rem;
      img{
        width: 0.22rem;
        height: auto;
        margin-left: 0.12rem;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>