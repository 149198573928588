import config from '@/config';
import user from '@/store/modules/user';


/**
 * Vuex插件，用于从vuex模块保存和同步“用户”.
 */
class SyncStorage {
    constructor(option) {
        /** 初始化配置*/
        this.storage = (window && window[option.storage]) || (window && window[config.storage]);
        this.prefix = option.prefix || config.prefix;
        this.ttl = option.ttl || config.ttl;
        this.keyOfUser = 'user';
        this.keyOfTTL = 'ttl';

        this.userMutations = this.getModuleOptions(user, 'mutations');
    }

    /**
     * Vuex 插件功能.
     * @param {Object} store Vuex 实例
     */
    subscribe = async (store) => {
        if (!this.checkStorage()) {
            throw new Error('[vuex.SyncStorage] 非法的 Storage实例');
        }

        if (this.checkVersion(config.version)) {
            console.log(`[vuex.SyncStorage] 当前版本 "${config.version}"`);
        } else {
            console.warn(`[vuex.SyncStorage] 当前版本更新到 "${config.version}"`);
        }

        this.initUserState(store);

        store.subscribe((mutation, state) => {
            
            if (this.userMutations.includes(mutation.type)) {
                if (state.user && state.user !== 'undefined') {
                    
                    const userjson = JSON.stringify(state.user);
                    if (userjson === '{}') {
                        this.removeFromStorage(`${this.prefix}${this.keyOfUser}`);
                    } else if (userjson) {
                        this.setToStorage(`${this.prefix}${this.keyOfUser}`, userjson);
                    }
                } else {
                    this.removeFromStorage(`${this.prefix}${this.keyOfUser}`);
                }
                if (mutation.type === 'SET_TOKEN') {
                    this.setToStorage(`${this.prefix}${this.keyOfTTL}`, this.getSeconds(this.ttl));
                }
            }
      
        });

    };
//获取当前时间(秒数)+TTL
    getSeconds = (ttl) => Math.floor(Date.now() / 1000) + (ttl || 0);
//获取模块配置选项
    getModuleOptions = (module, key) => {
        if (!module || !module[key]) return [];
        return Object.keys(module[key]);
    };

//检查Storage
    checkStorage() {
        try {
            this.storage.setItem(`${this.prefix}@@`, 1);
            this.storage.removeItem(`${this.prefix}@@`);
        } catch (err) {
            console.error(`[vuex.SyncStorage] Check storage failed: ${err}`);
            return false;
        }
        return true;
    }
//检查版本
    checkVersion(version) {
        try {
            if (this.getFromStorage(`${this.prefix}version`) === version) {
                return true;
            }

            this.storage.clear();
            this.setToStorage(`${this.prefix}version`, version);
        } catch (err) {
            console.error(`[vuex.SyncStorage] 检查版本错误: ${err}`);
        }
        return false;
    }

//初始化用户
    initUserState(store) {
        const userTTL = parseInt(this.getFromStorage(`${this.prefix}${this.keyOfTTL}`) || 0, 10);
        if (userTTL < this.getSeconds()) {
            console.warn('[vuex.SyncStorage] Session 过期了');
            return false;
        }
        const userState = this.getFromStorage(`${this.prefix}${this.keyOfUser}`);

        if (userState && userState !== 'undefined' && userState !== '{}') {
            store.commit('SET_USER', JSON.parse(userState));
            return true;
        }
        return false;
    }
//设置到Storage
    setToStorage(key, value) {
        try {
            this.storage.setItem(key, value);
        } catch (err) {
            console.error(`[vuex.SyncStorage] 设置 storage 项 [${key}] 失败: ${err}`);
            return false;
        }
        return true;
    }
//从Storage中获取
    getFromStorage(key) {
        try {
            const value = this.storage.getItem(key);
            return value;
        } catch (err) {
            console.error(`[vuex.SyncStorage] 获取 storage 项 [${key}] 失败: ${err}`);
        }
        return '';
    }
//从Storage中移除
    removeFromStorage(key) {
        try {
            this.storage.removeItem(key);
        } catch (err) {
            console.error(`[vuex.SyncStorage] 移除 storage 项 []${key}] 失败: ${err}`);
        }
    }
//清空Storage
    clearStorage() {
        this.storage.clear();
    }
}

export default function (syncStorageOption) {
    const syncStorage = new SyncStorage(syncStorageOption);
    return syncStorage.subscribe;
}
