<template>
  <div class="home">
    <banner></banner>
    <produce></produce>
    <consult></consult>
    <information></information>
    <classicCase></classicCase>
  </div>
</template>

<script>
import banner from '@/views/home/components/banner.vue';
import produce from '@/views/home/components/produce.vue';
import consult from '@/views/home/components/consult.vue';
import information from '@/views/home/components/information.vue';
import classicCase from '@/views/home/components/classicCase.vue';
export default {
  name:'home',
  data() {
    return {

    };
  },
  components:{
    banner,
    produce,
    consult,
    information,
    classicCase
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";
.home{
  font-size: 0.3rem;
}
</style>
