<template>
  <div class="solution">
    <div class="banner">
      <p class="title">建模助手——整体介绍</p>
      <p class="text">
        <span>基于Revit原生态的辅助，</span>让BIM工作者在翻模、出图、机电、
        土建、设计优化等基础上提升工作效率
      </p>
    </div>
    <div class="listBox">
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in bannerTipList"
          :key="index"
          @click="linkTo(item)"
        >
          <img :src="item.icon" class="icon" />
          <p class="name">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="bigTitle">方案亮点</p>
      <div class="mainList">
        <div class="item">
          <img src="https://fs.zhbim.com/imgs/tick_icon.png" class="img" />
          <div class="textBox">
            <p class="title">比Revit原生功能更适用</p>
            <p class="text">
              基于Revit原生功能，提取BIM建模中实际需要用到的功能整合优化，并融合了BIM工程师们的使用习惯，更顺手、更便捷快速。
            </p>
          </div>
        </div>
        <div class="item">
          <img src="https://fs.zhbim.com/imgs/add_icon.png" class="img" />
          <div class="textBox">
            <p class="title">支持大部分模型批量创建</p>
            <p class="text">
              基于对Cad的识别以及设计规范，快速创建土建、机电各类模型，较于传统的手动操作创建模型，建模效率呈指数提升。
            </p>
          </div>
        </div>
        <div class="item">
          <img src="https://fs.zhbim.com/imgs/price_icon.png" class="img" />
          <div class="textBox">
            <p class="title">价格友好，人人可用</p>
            <p class="text">插件定位、价格友好，无捆绑无限制，按需选择。</p>
          </div>
        </div>
        <div class="item">
          <img src="https://fs.zhbim.com/imgs/codes_icon.png" class="img" />
          <div class="textBox">
            <p class="title">微信扫码，随登随用</p>
            <p class="text">
              插件采取微信扫码登录授权的方式，随时随地扫码登录即可使用，不受时间、设备、地点的限制。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="main mainColor">
      <p class="bigTitle">方案架构</p>
      <img
        src="https://fs.zhbim.com/imgs/mobile/solution/solution_scheme.png"
        alt="方案架构"
        title="方案架构"
        class="img"
      />
      <div class="main1List">
        <div class="item">
          <span class="dot"></span>
          <div class="textBox">
            <p class="text1">
              各模块结合了各自对应的设计规范和几何算法，利用各专业模块的相互搭配，从建模的创建到BIM成果输出，能够高效、便捷地完成BIM工作
            </p>
          </div>
        </div>
        <div class="item">
          <span class="dot"></span>
          <div class="textBox">
            <p class="smallTitle">设计规范</p>
            <p class="text1">
              确定一般可用性原则和审美常识下的避免犯错的方法，以及一旦出现错误后的补救方案。
            </p>
          </div>
        </div>
        <div class="item">
          <span class="dot"></span>
          <div class="textBox">
            <p class="smallTitle">几何算法</p>
            <p class="text1">
              插件将建筑设计规范融入代码，使成果更加标准、规范。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <p class="bigTitle">需求场景</p>
      <div class="main2List">
        <div class="item">
          <div class="top">
            <img
              src="https://fs.zhbim.com/imgs/demand_icon1.png"
              alt="Revit原生功能不够“智能”，需要 优化功能、简化操作"
              title="Revit原生功能不够“智能”，需要 优化功能、简化操作"
              class="img"
            />
            <p class="smallTitle">
              Revit原生功能不够“智能”，需要 优化功能、简化操作
            </p>
          </div>
          <p class="text">
            Revit原生功能虽然可以满足BIM项目的基本实施，但是功能操作较为单一，BIM工程师往往需要使用多个功能才能完成一处模型调整，操作繁琐；
          </p>
        </div>
        <div class="item">
          <div class="top">
            <img
              src="https://fs.zhbim.com/imgs/demand_icon2.png"
              alt="BIM建模重复性工作过多，需要提升效率，解放生产力"
              title="BIM建模重复性工作过多，需要提升效率，解放生产力"
              class="img"
            />
            <p class="smallTitle">
              BIM建模重复性工作过多，需要提升效率，解放生产力
            </p>
          </div>
          <p class="text">
            BIM多专业建模中存在大量重复性但很有价值的工作，BIM工程师需要花费大量的时间使用Revit原生功能创建模型，工作效率低，不利于推动项目进展，增加企业竞争力；
          </p>
        </div>
        <div class="item">
          <div class="top">
            <img
              src="https://fs.zhbim.com/imgs/demand_icon3.png"
              alt="Revit插件价格居高不下，急需降温普及受众"
              title="Revit插件价格居高不下，急需降温普及受众"
              class="img"
            />
            <p class="smallTitle">Revit插件价格居高不下，急需降温普及受众</p>
          </div>
          <p class="text">
            目前市面上主流的Revit插件价格高昂，对于中小型的BIM团队是一笔不小的支出，对于个人来说更是遥不可及；
          </p>
        </div>
        <div class="item">
          <div class="top">
            <img
              src="https://fs.zhbim.com/imgs/demand_icon4.png"
              alt="Revit插件授权使用限制过多，急需灵活作业"
              title="Revit插件授权使用限制过多，急需灵活作业"
              class="img"
            />
            <p class="smallTitle">Revit插件授权使用限制过多，急需灵活作业</p>
          </div>
          <p class="text">
            目前市面上主流的Revit插件的使用大多受到了时间和设备的限制，对于工作方式灵活多变的BIMer们，往往造成了不小的影响。
          </p>
        </div>
      </div>
    </div>
    <div class="main main-bottom">
      <p class="bigTitle">辅助快速建模解决方案</p>
      <p class="text">
        主要为了解决BIM各专业在Revit建模时工作效率低的实际问题，将Revit原生功能、设计规范、BIM工程师工作习惯以及几何算法结合，让BIM建模工作回归软件。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperOption: {
        loop: true, // 循环模式选项
        loopedSlides: 3,
      },
      bannerTipList: [
        {
          title: "综合模块",
          icon: "https://fs.zhbim.com/imgs/webPC/solution/general_icon.png",
          link: "solutionGeneral",
        },
        {
          title: "土建模块",
          icon: "https://fs.zhbim.com/imgs/webPC/solution/aec_icon.png",
          link: "solutionAec",
        },
        {
          title: "机电模块",
          icon: "https://fs.zhbim.com/imgs/webPC/solution/mep_icon.png",
          link: "solutionMep",
        },
        {
          title: "出图模块",
          icon: "https://fs.zhbim.com/imgs/webPC/solution/draw_icon.png",
          link: "solutionDraw",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    linkTo(item) {
      this.$router.push({
        name: item.link,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.solution {
  .banner {
    background: url("https://fs.zhbim.com/imgs/mobile/solution/solution-banner.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .listBox {
    width: 100%;
    overflow-x: auto;
    margin-top: -0.2rem;
    padding-bottom: 0.2rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .list {
    width: 9.82rem;
    padding: 0px 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .item {
      width: 2.2rem;
      height: 1.2rem;
      text-align: center;
      line-height: 1.2rem;
      border: 2px solid #ffffff;
      background: linear-gradient(180deg, #eff1f6 0%, #ffffff 100%);
      box-shadow: 0.05rem 0.03rem 0.06rem 0px rgba(232, 235, 247, 0.86);
      border-radius: 0.04rem;
      .icon,
      .name {
        display: inline-block;
        vertical-align: middle;
      }
      .icon {
        width: 0.52rem;
        height: 0.52rem;
        margin-right: 0.15rem;
      }
      .name {
        font-size: 0.28rem;
        font-weight: 500;
        color: #333;
      }
    }
  }
}
</style>