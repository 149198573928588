<template>
  <div class="Footer">
    <van-collapse v-model="activeCode" accordion>
      <van-collapse-item :title="item.title" :name="item.activeCode" v-for="item,index in footList" :key="index">
        <ul>
          <li v-for="itemLi,indexLi in item.list" :key="indexLi">
            <a :href="itemLi.url">{{itemLi.label}}</a>
          </li>
        </ul>
      </van-collapse-item>
    </van-collapse>
    <div class="bottom">
      <mt-button type="primary" @click="onDial">资讯热线：18924305303</mt-button>
      <span>©2019 中恒信息科技（广州）有限公司 All Rights Reserved. 粤ICP备2021066427号-1</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCode: '',
      footList: [
        {
          title: '热门推荐',
          activeCode:'1',
          list: [
            {
              label: '有求必应',
              url: '/productCenter?id=ZH.Plugin.YQBY&boxId=module'
            },
            {
              label: '综合模块',
              url: '/productCenter?id=ZH.Plugin.General&boxId=module'
            },
            {
              label: '土建模块',
              url: '/productCenter?id=ZH.Plugin.AEC&boxId=module'
            },
            {
              label: '机电模块',
              url: '/productCenter?id=ZH.Plugin.MEP&boxId=module'
            },
            {
              label: '出图模块',
              url: '/productCenter?id=ZH.Plugin.Draw&boxId=module'
            },
            {
              label: '施工模块',
              url: '/productCenter?id=ZH.Plugin.construction&boxId=module'
            },
            {
              label: '族库模块',
              url: '/productCenter?id=ZH.Plugin.Family&boxId=module'
            }

          ]
        },
        {
          title: '资源与资讯',
          activeCode:'2',
          list: [
            {
              label: '教学视频',
              url: '/study'
            },
            {
              label: '项目视频',
              url: '/education?boxId=video'
            },
            {
              label: '专栏资讯',
              url: '/informationCenter'
            }
          ]
        },
        {
          title: '服务与支持',
          activeCode:'3',
          list: [
            {
              label: '有求必应',
              url: '/responsive'
            },
            {
              label: '联系我们',
              url: '/about?boxId=relation'
            }
          ]
        },
        {
          title: '建模助手',
          activeCode:'4',
          list: [
            {
              label: '最新动态',
              url: '/serviceAffiche'
            },
            {
              label: '项目案例',
              url: '/case'
            },
            {
              label: '关于我们',
              url: '/about'
            }
          ]
        }
      ]
    };
  },
  methods: {
    onDial(){
      window.location.href='tel:18924305303'
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";
.Footer {
  background: #fff;
  // padding: 30px;
  ::v-deep .van-collapse-item__wrapper {
    background-color: #f4f5f9;
    box-shadow: 0px 8px 10px 0px rgba(213, 219, 243, 0.6);
    padding: 0 0.3rem;
    padding-top: 0.42rem;
    .van-collapse-item__content {
      background-color: #f4f5f9;
      padding: 0;
      li {
        padding-bottom: 0.42rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
      }
    }
  }
  ::v-deep .van-cell {
    height: 1.2rem;
    border: 2px solid #ffffff;
    background: linear-gradient(180deg, #f4f5f9 0%, #ffffff 100%);
    box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
    line-height: 1.2rem;
    padding: 0 0.3rem;
    font-size: 0.32rem;
    color: #333;
    .van-cell__left-icon,
    .van-cell__right-icon {
      line-height: 1.2rem;
    }
  }
  ::v-deep .van-collapse-item__title--expanded {
    color: #4a86ff;
    .van-cell__left-icon,
    .van-cell__right-icon {
      color: #4a86ff;
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0.32rem;
    .mint-button {
      width: 6.9rem;
      height: 0.88rem;
      background-color: #4a86ff;
      border-radius: 0;
      margin-top: 0.56rem;
      margin-bottom: 0.5rem;
    }
    span {
      width: 4.96rem;
      text-align: center;
      font-size: 0.2rem;
      color: #666;
      line-height: 0.32rem;
    }
  }
}
</style>

