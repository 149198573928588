//关于有求必应的接口
import request from '@/utils/request'


//获取热门需求
export function getHotRequirements(params) {
    return request({
        url: '/requirement/wish/hot/get',
        method: 'get',
        params: params
    })
}

// 新增需求    api/requirement/add
export function requirementAdd(data) {
    return request({
        url: 'requirement/add',
        method: 'post',
        data
    })
}
