const user = {
  state: {
    token: null //token
  },
  mutations: {
    SET_TOKEN: (state, payload) => {
      state.token = payload
    }
  },

  actions: {
    //登录
    Login({
      commit
    }, getCode) {
      return new Promise((resolve, reject) => {

      })
    },
    //获取当前用户信息
    getUserInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {

      })
    },
    //退出登录
    LogOut({
      commit
    }, userId) {
      return new Promise((resolve, reject) => {

      })
    },
  }
};

export default user;
