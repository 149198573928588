
<template>
  <div class="contain downFile">
    <div class="navigation">
      <a
        class="name active"
        title="首页"
        alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手"
        href="/"
        >首页</a
      >
      <img
        class="icon"
        src="@/assets/img/rightTwo.png"
        alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手"
        title="文档详情"
      />
      <span class="name">文档详情</span>
    </div>
    <div class="main">
      <img
        :src="detail.path"
        alt="BIM建模_BIM软件_Revit教程_Revit插件_BIM建模助手"
        :title="detail.title"
      />
    </div>
  </div>
</template>

<script>
import { getResourcesSingle, classPoint } from "@/api/resource";
export default {
  data() {
    return {
      TutorialId: "",
      detail: "",
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
      this.getPoint(this.$route.params.id);
    }
  },
  methods: {
    getDetail(id) {
      getResourcesSingle({
        id: id,
      }).then((res) => {
        this.detail = res;
      });
    },
    getPoint(id) {
      //数据埋点
      classPoint({
        tutorialId: id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/static/variables.scss";

.downFile {
  background-color: #f6f6f6;
  .navigation{
    padding: 0.2rem;
    img{
      margin: 0 0.1rem;
    }
  }
  .main {
    background-color: $activeBt;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
