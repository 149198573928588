<template>
  <div class="solution solution-general">
    <div class="banner">
      <p class="title">建模助手——出图</p>
      <p class="text">
        <span>Revit出图插件BIM工作中的最后一公里，</span
        ><br />让Revit在操作中避免卡顿，最终效果的呈现可以节省客户看图与沟通的时间成本，将工作效率达到最大化
      </p>
      <a href="/study?moduleId=ZH.Plugin.Draw" class="linkBtn">
        <img
          src="https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"
          class="img"
        />
        <span class="name">产品进修</span>
      </a>
    </div>
    <div class="listBox">
      <div class="list">
        <div class="item" v-for="(item, index) in tipList" :key="index">
          <p class="p1">{{ item.title }}</p>
          <p class="p2">{{ item.des1 }}</p>
          <p class="p2">{{ item.des2 }}</p>
        </div>
      </div>
    </div>
    <div class="videoPd">
      <div class="videoBox">
        <video-item
          :item="{
            path: 'https://fs.zhbim.com/imgs/webPC/solution/draw_video.mp4',
          }"
          pageName="解决方案"
          :showOfficial="false"
        ></video-item>
      </div>
    </div>
    <div class="main mainGenera">
      <p class="bigTitle">产品特性</p>
      <div class="mainGeneralList">
        <div class="item">
          <p class="title">视图类：视图创建、调整、管理，全方位控制视图</p>
          <div class="textBox">
            <p class="text1">构件显隐，避免卡顿，一键显示\隐藏单个构件；</p>
            <p class="text1">局部三维、快速剖面、快速局部平面，视图一键创建；</p>
            <p class="text1">快速视图样板，一键应用、编辑、分享指定样板。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/makemap1.jpg"
            alt="出图模块"
            title="视图类：视图创建、调整、管理，全方位控制视图"
            class="img"
          />
        </div>
        <div class="item item-color">
          <p class="title">标注类：除去繁杂的设置，一键添加标注</p>
          <div class="textBox">
            <p class="text1">管线的文字标注集中添加；</p>
            <p class="text1">
              轴网、构件尺寸定位快速创建、调整。
            </p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/makemap2.jpg"
            alt="出图模块"
            title="标注类：除去繁杂的设置，一键添加标注"
            class="img"
          />
        </div>
        <div class="item" v-if="infoShow">
          <p class="title">预留预埋：管线开洞、标注、出图，一步到位</p>
          <div class="textBox">
            <p class="text1">这里没什么好介绍的了，反正你们都懂的</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/makemap3.jpg"
            alt="出图模块"
            title="预留预埋：管线开洞、标注、出图，一步到位"
            class="img"
          />
        </div>
        <div class="item item-color" v-if="infoShow">
          <p class="title">净高分析：净高分析、调整、出图，一步到位</p>
          <div class="textBox">
            <p class="text1">这里也是，毕竟用我的人都是大触</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/makemap4.jpg"
            alt="出图模块"
            title="净高分析：净高分析、调整、出图，一步到位"
            class="img"
          />
        </div>
        <div class="item" v-if="infoShow">
          <p class="title">图纸类：完成Revit出图最后一公里</p>
          <div class="textBox">
            <p class="text1">这里没什么好介绍的了，反正你们都懂的</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/makemap5.jpg"
            alt="出图模块"
            title="图纸类：完成Revit出图最后一公里"
            class="img"
          />
        </div>
      </div>
    </div>
    <div class="lookMore" @click="lookMore(3)" v-if="!infoShow">
      展开查看更多
      <img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png"
        class="img"
      />
    </div>
    <solutionCommon
      :fqaList="fqaList"
      :dynamicList="dynamicList"
    ></solutionCommon>
  </div>
</template>
<script>
import videoItem from "@/components/videoItem";
import solutionCommon from "@/views/solution/common";
import { getFqaList } from "@/api/FQA";
export default {
  name: "general",
  data() {
    return {
      tipList: [
        {
          title: "新用户福利",
          des1: "注册即可免费体验",
          des2: "企业用户最高可获60天体验时长",
        },
        {
          title: "限时秒杀",
          des1: "1天仅需1元",
          des2: "只为让你提早下班",
        },
        {
          title: "有求必应",
          des1: "与你共同参与设计",
          des2: "快速响应 加量不加价",
        },
      ],
      infoShow: false,
      fqaList: [], //常见问题列表
      dynamicList: [
        {
          time: '2021-10',
          content: '修复【一键开洞】存在部分模块无法生成洞口的问题。'
        },
        {
          time: '2021-10',
          content: '优化【净高分析】净高数据保留至0.00，0.05位。'
        },
        {
          time: '2021-10',
          content: '修复【管线标注】部分剖面无法使用功能的问题。'
        },
        {
          time: '2021-10',
          content: '修复【尺寸定位标注】剖面无法使用功能的问题。'
        },
        {
          time: '2021-10',
          content: '优化【标注避让】支持选择避让方向。'
        },
        {
          time: '2021-08',
          content: '修复【净高平面图】净高平面图无法再次生成的问题'
        },
      ],
    };
  },
  components: {
    videoItem,
    solutionCommon,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getFqaList({ productCode: "ZH.Plugin.Draw" }).then((res) => {
        this.fqaList = res;
      });
    },
    lookMore(type) {
      if (type == 3) {
        this.infoShow = !this.infoShow;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.solution {
  .banner {
    background: url("https://fs.zhbim.com/imgs/mobile/solution/solution-draw-banner.png")
      no-repeat top;
    background-size: 100% 100%;
  }
}
</style>