<template>
  <div class="solution solution-general">
    <div class="banner">
      <p class="title">建模助手——土建</p>
      <p class="text">
        <span>Revit土建插件处理快批量大，</span
        ><br />根据国人使用习惯而研发让BIM工作者的本地化快速建模，最大化提高工作效率
      </p>
      <a href="/study?moduleId=ZH.Plugin.AEC" class="linkBtn">
        <img
          src="https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"
          class="img"
        />
        <span class="name">产品进修</span>
      </a>
    </div>
    <div class="listBox">
      <div class="list">
        <div class="item" v-for="(item, index) in tipList" :key="index">
          <p class="p1">{{ item.title }}</p>
          <p class="p2">{{ item.des1 }}</p>
          <p class="p2">{{ item.des2 }}</p>
        </div>
      </div>
    </div>
    <div class="videoPd">
      <div class="videoBox">
        <video-item
          :item="{
            path: 'https://fs.zhbim.com/imgs/webPC/solution/aec_video.mp4',
          }"
          pageName="解决方案"
          :showOfficial="false"
        ></video-item>
      </div>
    </div>
    <div class="main mainGenera">
      <p class="bigTitle">产品特性</p>
      <div class="mainGeneralList">
        <div class="item">
          <p class="title">CAD图纸辨识：跟机电建模一样便捷~</p>
          <div class="textBox">
            <p class="text1">支持轴网、桩、承台、柱、墙、梁、门窗识别；</p>
            <p class="text1">更简洁的翻模流程识别→创建；</p>
            <p class="text1">高精确率与高识别率的双重选择。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/soilbuild1.jpg"
            alt="土建模块"
            title="CAD图纸辨识：跟机电建模一样便捷~"
            class="img"
          />
        </div>
        <div class="item item-color">
          <p class="title">精细化建模：只需要一个按钮，信吗?</p>
          <div class="textBox">
            <p class="text1">一键成板、一键基础垫层；</p>
            <p class="text1">
              结合规范，快速生成过梁、构造柱、圈梁等二次结构；
            </p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/soilbuild2.jpg"
            alt="土建模块"
            title="精细化建模：只需要一个按钮，信吗?"
            class="img"
          />
        </div>
        <div class="item" v-if="infoShow">
          <p class="title">模型调整：来试试，看看是不是真的好用</p>
          <div class="textBox">
            <p class="text1">墙齐梁板，柱断墙梁，梁随斜板，顶部对齐，</p>
            <p class="text1">模型整洁，一步到位</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/soilbuild3.jpg"
            alt="土建模块"
            title="模型调整：来试试，看看是不是真的好用"
            class="img"
          />
        </div>
      </div>
    </div>
    <div class="lookMore" @click="lookMore(3)" v-if="!infoShow">
      展开查看更多
      <img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png"
        class="img"
      />
    </div>
    <solutionCommon
      :fqaList="fqaList"
      :dynamicList="dynamicList"
    ></solutionCommon>
  </div>
</template>
<script>
import videoItem from "@/components/videoItem";
import solutionCommon from "@/views/solution/common";
import { getFqaList } from "@/api/FQA";
export default {
  name: "general",
  data() {
    return {
      tipList: [
        {
          title: "新用户福利",
          des1: "注册即可免费体验",
          des2: "企业用户最高可获60天体验时长",
        },
        {
          title: "限时秒杀",
          des1: "1天仅需1元",
          des2: "只为让你提早下班",
        },
        {
          title: "有求必应",
          des1: "与你共同参与设计",
          des2: "快速响应 加量不加价",
        },
      ],
      infoShow: false,
      fqaList: [], //常见问题列表
      dynamicList: [
        {
          time: "2021-10",
          content: "新增【精准生梁】识别CAD，精准生成梁",
        },
        {
          time: "2021-10",
          content: "修复【生成构造柱】生成构造柱的标高不准问题。",
        },
        {
          time: "2021-10",
          content: "修复【图转轴网】部分轴网无法识别生成问题。",
        },
        {
          time: "2021-10",
          content: "优化【快速分割】增加右击执行、增加删除分割后的小面积楼板。",
        },
        {
          time: "2021-08",
          content: "新增【链接CAD】快速链接CAD（设置带记忆功能）",
        },
        {
          time: "2021-08",
          content: "翻模功能完善：轴网、桩、柱、墙。",
        },
      ],
    };
  },
  components: {
    videoItem,
    solutionCommon,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getFqaList({ productCode: "ZH.Plugin.AEC" }).then((res) => {
        this.fqaList = res;
      });
    },
    lookMore(type) {
      if (type == 3) {
        this.infoShow = !this.infoShow;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.solution {
  .banner {
    background: url("https://fs.zhbim.com/imgs/mobile/solution/solution-aec-banner.png")
      no-repeat top;
    background-size: 100% 100%;
  }
}
</style>