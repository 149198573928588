<template>
  <div class="solution solution-general">
    <div class="banner">
      <p class="title">建模助手——综合</p>
      <p class="text">
        <span>让Revit操作起来更便捷，更具人性化，</span
        >真正帮助BIM工作者在Revit的插件中
        运用中做到一模多用，用得更顺手、用得更开心，提高工作效率
      </p>
      <a href="/study?moduleId=ZH.Plugin.General" class="linkBtn">
        <img
          src="https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"
          class="img"
        />
        <span class="name">产品进修</span>
      </a>
    </div>
    <div class="listBox">
      <div class="list">
        <div class="item" v-for="(item, index) in tipList" :key="index">
          <p class="p1">{{ item.title }}</p>
          <p class="p2">{{ item.des1 }}</p>
          <p class="p2">{{ item.des2 }}</p>
        </div>
      </div>
    </div>
    <div class="videoPd">
      <div class="videoBox">
        <video-item
          :item="{
            path: 'https://fs.zhbim.com/imgs/webPC/solution/general_video.mp4',
          }"
          pageName="建模进修"
          :showOfficial="false"
        ></video-item>
      </div>
    </div>
    <div class="main mainGenera">
      <p class="bigTitle">产品特性</p>
      <div class="mainGeneralList">
        <div class="item">
          <p class="title">界面自定义：只看你用的功能</p>
          <div class="textBox">
            <p class="text1">选项卡随心显示隐藏；</p>
            <p class="text1">经典背景色（黑/白）快速来回切换；</p>
            <p class="text1">一键多屏扩展，建模无边界；</p>
            <p class="text1">快速常用文件夹，WINDOWS的也能管一管。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/synthesize1.gif"
            alt="综合模块"
            title="界面自定义：只看你用的功能"
            class="img"
          />
        </div>
        <div class="item item-color">
          <p class="title">便捷管理轻松建模：我只关心你的效率</p>
          <div class="textBox">
            <p class="text1">项目族统一管理，标准分类，</p>
            <p class="text1">创建、修改、删除一步到位；</p>
            <p class="text1">标高管理，快速精准创建标高。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/synthesize2.gif"
            alt="综合模块"
            title="便捷管理轻松建模：我只关心你的效率"
            class="img"
          />
        </div>
        <div class="item" v-if="infoShow">
          <p class="title">三维、剖面随心创：你想怎么剖我都不反抗</p>
          <div class="textBox">
            <p class="text1">框选、点选生成局部三维，还可以记录、定位问题；</p>
            <p class="text1">横着剖、竖着剖、斜着剖，快速生成剖面。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/synthesize3.gif"
            alt="综合模块"
            title="三维、剖面随心创：你想怎么剖我都不反抗"
            class="img"
          />
        </div>
        <div class="item item-color" v-if="infoShow">
          <p class="title">链接模型：效率，效率，还是效率！</p>
          <div class="textBox">
            <p class="text1">批量链接，一次链“10”个模型；</p>
            <p class="text1">编辑链接，跨界添加、删除链接中的构件；</p>
            <p class="text1">打开链接，双开Revit打开链接模型。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/synthesize4.gif"
            alt="综合模块"
            title="链接模型：效率，效率，还是效率"
            class="img"
          />
        </div>
        <div class="item" v-if="infoShow">
          <p class="title">视图操控：所见即所得</p>
          <div class="textBox">
            <p class="text1">实时识别当前视图过滤器，操作可见性；</p>
            <p class="text1">类别组合，一键控制常用类别显示/隐藏；</p>
            <p class="text1">元素快速着色，点哪哪出彩。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/synthesize5.gif"
            alt="综合模块"
            title="视图操控：所见即所得"
            class="img"
          />
        </div>
        <div class="item item-color" v-if="infoShow">
          <p class="title">功能尝新，脑洞大开：你敢用我敢做</p>
          <div class="textBox">
            <p class="text1">通用放置，快速创建构件、变更构件类型；</p>
            <p class="text1">图转文字，识别CAD文字赋值给构件属性；</p>
            <p class="text1">两点对齐，任意两个构件上的两点高度对齐。</p>
          </div>
          <img
            src="https://fs.zhbim.com/imgs/mobile/solution/synthesize6.gif"
            alt="综合模块"
            title="功能尝新，脑洞大开：你敢用我敢做"
            class="img"
          />
        </div>
      </div>
    </div>
    <div class="lookMore" @click="lookMore(3)" v-if="!infoShow">
      展开查看更多
      <img
        src="https://fs.zhbim.com/imgs/mobile/product/down.png"
        class="img"
      />
    </div>
    <solutionCommon
      :fqaList="fqaList"
      :dynamicList="dynamicList"
    ></solutionCommon>
  </div>
</template>
<script>
import videoItem from "@/components/videoItem";
import solutionCommon from "@/views/solution/common";
import { getFqaList } from "@/api/FQA";
export default {
  name: "general",
  data() {
    return {
      tipList: [
        {
          title: "新用户福利",
          des1: "注册即可免费体验",
          des2: "企业用户最高可获60天体验时长",
        },
        {
          title: "限时秒杀",
          des1: "1天仅需1元",
          des2: "只为让你提早下班",
        },
        {
          title: "有求必应",
          des1: "与你共同参与设计",
          des2: "快速响应 加量不加价",
        },
      ],
      infoShow:false,
      fqaList: [], //常见问题列表
      dynamicList: [
        //动态列表
        {
          time: "2021-10",
          content: "新增【万能刷】根据选择的构件信息，快速赋值给其它的构件。",
        },
        {
          time: "2021-10",
          content: "新增【实时轴号】实时显示视图范围轴网对应的轴号。",
        },
        {
          time: "2021-08",
          content: "新增【超级过滤】多条件快速精确筛选项目所有构件。",
        },
        {
          time: "2021-08",
          content: "永久移除【加强过滤】功能。",
        },
        {
          time: "2021-08",
          content: "完善【问题视图】支持设置视图样板。",
        },
        {
          time: "2021-08",
          content: "完善【标高管理】支持自动给楼层命名（1F、B1等）。",
        },
      ],
    };
  },
  components: {
    videoItem,
    solutionCommon,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getFqaList({ productCode: "ZH.Plugin.General" }).then((res) => {
        this.fqaList = res;
      });
    },
    lookMore(type){
      if(type == 3){
        this.infoShow = !this.infoShow;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.solution {
  .banner {
    background: url("https://fs.zhbim.com/imgs/mobile/solution/solution-general-banner.png")
      no-repeat top;
    background-size: 100% 100%;
  }
}
</style>