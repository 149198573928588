import Vue from 'vue'
import App from './App.vue';
import router from './router'
import store from "./store";

import "babel-polyfill";
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI);

import '@/static/common.css';
import '@/static/mint-ui.css';
import '@/static/solution.css';

import './permission'


import Collapse from 'vant/lib/collapse';
import 'vant/lib/collapse/style';
import CollapseItem from 'vant/lib/collapse-item';
import 'vant/lib/collapse-item/style';
import Pagination from 'vant/lib/pagination';
import 'vant/lib/pagination/style';
import Icon from 'vant/lib/icon';
import 'vant/lib/icon/style';
import ImagePreview from 'vant/lib/image-preview';
import 'vant/lib/image-preview/style';
import Overlay from 'vant/lib/overlay';
import 'vant/lib/overlay/style';
import Search  from 'vant/lib/search';
import 'vant/lib/search/style';

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Pagination);
Vue.use(Icon);
Vue.use(ImagePreview);
Vue.use(Overlay);
Vue.use(Search);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)


import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(VideoPlayer)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '5fc72b5b3736af2c1b7d54df734ae2a4'
})

// 在 iOS中，点击输入框之后无法获取焦点问题
import FastClick from 'fastclick'
if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function () {
    FastClick.attach(document.body);
  }, false);
}

FastClick.prototype.focus = function (targetElement) {
  let length;
  if (
    targetElement.setSelectionRange &&
    targetElement.type.indexOf("date") !== 0 &&
    targetElement.type !== "time" &&
    targetElement.type !== "month"
  ) {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
}

//判断是否使用Mock
console.log('[Main]  NODE_ENV:', process.env.NODE_ENV, 'Mock:', process.env.VUE_APP_MOCK);
if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_MOCK === 'true') {
  require('./mock/index.js');
  console.log('[Main] Mock loaded.');
}

import axios from "axios";
console.log('router', router)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')

