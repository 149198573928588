<template>
  <div class="Header">
    <a href="/">
      <img
        class="left"
        src="https://fs.zhbim.com/imgs/mobile/home/logo.png"
        alt=""
      />
    </a>
    <img
      v-if="!show"
      @click="show = true"
      class="right"
      src="https://fs.zhbim.com/imgs/mobile/home/catalog.png"
      alt=""
    />
    <img
      v-else
      @click="show = false"
      class="right"
      src="https://fs.zhbim.com/imgs/mobile/home/close.png"
      alt=""
    />
    <ul class="list" v-show="show">
      <li
        :class="active === index ? 'active' : ''"
        @click="cutTab(item, index)"
        v-for="(item, index) in list"
        :key="index"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: null,
      show: false,
      list: [
        {
          title: "最新活动",
          url: "/activity",
        },
        {
          title: "解决方案",
          url: "/solution",
        },
        {
          title: "产品中心",
          url: "/productCenter",
        },
        {
          title: "应用案例",
          url: "/case",
        },
        {
          title: "建模进修",
          url: "/education",
        },
        {
          title: "客户支持",
          url: "/customer",
        },
        {
          title: "关于我们",
          url: "/about",
        },
      ],
    };
  },
  created() {
    let url = this.$route.path;
    this.list.forEach((item, index) => {
      if (url.indexOf(item.url) != -1) {
        this.active = index;
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    cutTab(item, index) {
      this.active = index;
      this.$router.push({
        path: item.url,
      });
      this.show = false;
    },
    handleScroll() {
      if (this.show) {
        this.show = false;
      }
    },
  },
};
</script>
<style lang="scss" scope>
@import "@/static/variables.scss";
.Header {
  background: #fff;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #cfcfcf;
  .left {
    width: 1.78rem;
    height: 0.43rem;
  }
  .right {
    width: 0.36rem;
    height: 0.36rem;
  }
  .list {
    z-index: 999;
    position: absolute;
    top: 1.15rem;
    right: 0px;
    background: #fff;
    font-size: 0.3rem;
    color: #333;
    box-shadow: 5px 3px 6px 0px rgba(232, 235, 247, 0.86);
    li {
      padding: 0 0.7rem;
      line-height: 1rem;
    }
    .active {
      background: linear-gradient(180deg, #f4f5f9 0%, #f1f6fd 100%);
      color: #4a86ff;
    }
  }
}
</style>