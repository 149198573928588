var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution solution-general"},[_vm._m(0),_c('div',{staticClass:"listBox"},[_c('div',{staticClass:"list"},_vm._l((_vm.tipList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des1))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.des2))])])}),0)]),_c('div',{staticClass:"videoPd"},[_c('div',{staticClass:"videoBox"},[_c('video-item',{attrs:{"item":{
          path: 'https://fs.zhbim.com/imgs/webPC/solution/aec_video.mp4',
        },"pageName":"解决方案","showOfficial":false}})],1)]),_c('div',{staticClass:"main mainGenera"},[_c('p',{staticClass:"bigTitle"},[_vm._v("产品特性")]),_c('div',{staticClass:"mainGeneralList"},[_vm._m(1),_vm._m(2),(_vm.infoShow)?_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("模型调整：来试试，看看是不是真的好用")]),_vm._m(3),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/soilbuild3.jpg","alt":"土建模块","title":"模型调整：来试试，看看是不是真的好用"}})]):_vm._e()])]),(!_vm.infoShow)?_c('div',{staticClass:"lookMore",on:{"click":function($event){return _vm.lookMore(3)}}},[_vm._v(" 展开查看更多 "),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/product/down.png"}})]):_vm._e(),_c('solutionCommon',{attrs:{"fqaList":_vm.fqaList,"dynamicList":_vm.dynamicList}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("建模助手——土建")]),_c('p',{staticClass:"text"},[_c('span',[_vm._v("Revit土建插件处理快批量大，")]),_c('br'),_vm._v("根据国人使用习惯而研发让BIM工作者的本地化快速建模，最大化提高工作效率 ")]),_c('a',{staticClass:"linkBtn",attrs:{"href":"/study?moduleId=ZH.Plugin.AEC"}},[_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/synthesize_product_center.png"}}),_c('span',{staticClass:"name"},[_vm._v("产品进修")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("CAD图纸辨识：跟机电建模一样便捷~")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("支持轴网、桩、承台、柱、墙、梁、门窗识别；")]),_c('p',{staticClass:"text1"},[_vm._v("更简洁的翻模流程识别→创建；")]),_c('p',{staticClass:"text1"},[_vm._v("高精确率与高识别率的双重选择。")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/soilbuild1.jpg","alt":"土建模块","title":"CAD图纸辨识：跟机电建模一样便捷~"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item-color"},[_c('p',{staticClass:"title"},[_vm._v("精细化建模：只需要一个按钮，信吗?")]),_c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("一键成板、一键基础垫层；")]),_c('p',{staticClass:"text1"},[_vm._v(" 结合规范，快速生成过梁、构造柱、圈梁等二次结构； ")])]),_c('img',{staticClass:"img",attrs:{"src":"https://fs.zhbim.com/imgs/mobile/solution/soilbuild2.jpg","alt":"土建模块","title":"精细化建模：只需要一个按钮，信吗?"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textBox"},[_c('p',{staticClass:"text1"},[_vm._v("墙齐梁板，柱断墙梁，梁随斜板，顶部对齐，")]),_c('p',{staticClass:"text1"},[_vm._v("模型整洁，一步到位")])])
}]

export { render, staticRenderFns }