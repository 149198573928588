// 路由守卫处理
import router from './router'

router.beforeEach(async (to, from, next) => {
  var prevurl;
  if (document.referrer.length > 0) {
    prevurl = document.referrer;
    if (prevurl.indexOf('linkParams') != -1) {
      function addParamsToUrl(url, key, val) {
        if (!val) {
          return url;
        }
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = url.indexOf('?') !== -1 ? "&" : "?";
        if (url.match(re)) {
          return url.replace(re, '$1' + key + "=" + val + '$2');
        } else {
          return url + separator + key + "=" + val;
        }
      }
      var adress = addParamsToUrl(window.location.href, 'linkParams', 'wechat')
      location.replace(adress)
    }else{
      next();
    }
  }else{
    next();
  }
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
})
